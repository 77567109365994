import Poster from 'components/Poster';
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import getOuterSize from 'utils/getOuterSize';
import { Content } from 'types/content';
import { navigation } from 'ConfigProvider';
import { NAVIGATION_PARENT_NODES } from 'types/navigation';
import useMoveToPlayer from 'hooks/useMoveToPlayer';
import cn from 'classnames';
import useRatioStrategy from 'hooks/useRatioStrategy';
import { Directions } from 'lrud';
import {
  selectIsMagicRemoteActive,
  selectReturnState,
} from 'store/navigation/selectors';
import { ReactComponent as ArrowNavigator } from 'assets/icons/arrow-navigation.svg';
import { useNavigationStore } from '../../store/navigation';
import useThrottledWheel from '../../hooks/useThrottledWheel';
import { useMenuStore } from '../../store/menu';
import { selectIsMenuOpened } from '../../store/menu/selectors';
import styles from './index.module.scss';
import {
  LISTING_GRID_ACTIVE_INITIAL_VALUE,
  LISTING_GRID_INITIAL_VALUE,
  LISTING_POSTER_MARGIN,
} from './constants';
import { generateChildPosterNodeId, generatePosterRowNodeId } from './helpers';
import { ListingGridActive, ListingGridData } from './types';

const BOTTOM_ACTIVE_MAGIC_POINTER_INDEX = 4;

interface PosterListProps {
  contents: Content[];
  parentNodeId: string;
  categoryId: number;
}

const PosterList = ({
  contents,
  parentNodeId,
  categoryId,
}: PosterListProps) => {
  const isMenuOpened = useMenuStore(selectIsMenuOpened);
  const { posterWidth, getListingPosterTransformValue } = useRatioStrategy();
  const posterTotalWidth = posterWidth + LISTING_POSTER_MARGIN;
  const gridRef = useRef<HTMLDivElement>(null);
  const posterRef = useRef<HTMLDivElement>(null);
  const posterItemsParent = generatePosterRowNodeId(parentNodeId, 'poster');
  const {
    magicRemoteActive,
    returnState: { returnPosterIndex },
    setReturnState,
  } = useNavigationStore((state) => ({
    magicRemoteActive: selectIsMagicRemoteActive(state),
    returnState: selectReturnState(state),
    setReturnState: state.setReturnState,
  }));
  const [activeColumn, setActiveColumn] = useState<ListingGridActive>(
    LISTING_GRID_ACTIVE_INITIAL_VALUE
  );
  const [gridData, setGridData] = useState<ListingGridData>(
    LISTING_GRID_INITIAL_VALUE
  );
  const posterTransformValue = getListingPosterTransformValue(
    activeColumn.rowIndex
  );

  const moveToPlayer = useMoveToPlayer();

  const gridDimensionsCalculated = gridData.col && gridData.row;
  const goDown = () =>
    navigation.go(NAVIGATION_PARENT_NODES.LISTING_NODE_ID, Directions.DOWN);
  const goUp = () =>
    navigation.go(NAVIGATION_PARENT_NODES.LISTING_NODE_ID, Directions.UP);
  const posterOnClickHandler = (content: Content) => {
    setReturnState({
      returnCollectionIndex: 0,
      returnPosterIndex: activeColumn.childIndex,
    });
    moveToPlayer({ categoryId, contentId: content.id });
  };

  const posterFocusHandler = ({
    posterNodeId,
    rowIndex,
    childIndex,
  }: {
    posterNodeId: string;
    rowIndex: number;
    childIndex: number;
  }) => setActiveColumn({ rowIndex, posterNodeId, childIndex });

  // Calculate how many elements is per row and how many columns
  useLayoutEffect(() => {
    if (!gridRef.current) {
      return;
    }

    const gridWidth = getOuterSize(gridRef.current, 'width');
    const totalCount = contents.length;

    if (posterTotalWidth) {
      const colCount = Math.floor(gridWidth / posterTotalWidth);

      setGridData({
        row: Math.ceil(totalCount / colCount),
        col: colCount,
      });
    }
  }, [contents, posterTotalWidth]);

  //Unregister created nodes on exit
  useEffect(() => {
    return () => {
      if (gridDimensionsCalculated) {
        for (let parentIndex = 0; parentIndex < gridData.row; parentIndex++) {
          const nodeId = generatePosterRowNodeId(
            posterItemsParent,
            parentIndex
          );
          navigation.unregisterChildrenNodes(nodeId);
          navigation.unregisterNode(nodeId);
        }
      }
    };
  }, [gridData.row, gridDimensionsCalculated, posterItemsParent]);

  //Register parent nodes - rows
  useLayoutEffect(() => {
    if (!gridDimensionsCalculated) return;

    for (let parentIndex = 0; parentIndex < gridData.row; parentIndex++) {
      navigation.registerParentNode(
        generatePosterRowNodeId(posterItemsParent, parentIndex),
        {
          orientation: 'horizontal',
          parent: NAVIGATION_PARENT_NODES.LISTING_NODE_ID,
        }
      );
    }
  }, [gridDimensionsCalculated, gridData, posterItemsParent, parentNodeId]);

  const contentsToRender = useMemo(() => {
    if (!gridDimensionsCalculated) return [];

    return contents.map((content, i) => {
      const index = Math.floor(i / gridData.col);
      const parentIndex = isNaN(index) ? 0 : index;
      const nodeId = generateChildPosterNodeId(i);
      const shouldAssignFocus = returnPosterIndex === i || i === 0;

      return {
        ...content,
        nodeId,
        parentIndex,
        shouldAssignFocus,
      };
    });
  }, [contents, gridData.col, gridDimensionsCalculated, returnPosterIndex]);

  useThrottledWheel(
    useCallback(
      ({ deltaY }) => {
        if (isMenuOpened) return;
        navigation.go(
          parentNodeId,
          deltaY > 0 ? Directions.DOWN : Directions.UP
        );
      },
      [isMenuOpened, parentNodeId]
    )
  );

  return (
    <div>
      {magicRemoteActive && !!activeColumn.rowIndex && (
        <ArrowNavigator
          onClick={goUp}
          className={cn(styles.arrow, styles.arrowUp)}
        />
      )}
      <div
        className={cn(styles.posterList)}
        ref={gridRef}
        style={{
          transform: `translate3d(0, -${posterTransformValue}px, 0)`,
        }}
      >
        {contentsToRender.map((content, i) => {
          const parentId = generatePosterRowNodeId(
            posterItemsParent,
            content.parentIndex
          );

          const isActive =
            `${parentId}_${content.nodeId}` === activeColumn.posterNodeId;

          return (
            <Poster
              key={content.title + i}
              className={cn(styles.posterListItem, {
                [styles.fadeIn]:
                  content.parentIndex > activeColumn.rowIndex - 1 &&
                  i % gridData.row < gridData.row,
              })}
              ref={posterRef}
              nodeId={content.nodeId}
              parentNodeId={parentId}
              index={i}
              onClick={() => posterOnClickHandler(content)}
              onFocus={(nodeId) =>
                posterFocusHandler({
                  posterNodeId: nodeId,
                  rowIndex: content.parentIndex,
                  childIndex: i,
                })
              }
              assignFocus={content.shouldAssignFocus}
              data={content}
              isActive={isActive}
              options={{
                disableFrame: magicRemoteActive,
              }}
            />
          );
        })}
      </div>
      {magicRemoteActive &&
        activeColumn.rowIndex <
          gridData.row - BOTTOM_ACTIVE_MAGIC_POINTER_INDEX && (
          <ArrowNavigator
            onClick={goDown}
            className={cn(styles.arrow, styles.arrowBottom)}
          />
        )}
    </div>
  );
};

export default memo(PosterList);
