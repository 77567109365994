import { ManifestResponse, fetchSrcFromManifest } from 'actions/playback';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppStore } from 'store/app';
import { selectContent } from 'store/app/selectors';

interface PlayerPageParams {
  categoryId: string;
  contentId: string;
}

const usePlayerAsset = () => {
  const { categoryId, contentId } = useParams<PlayerPageParams>();
  const { content } = useAppStore((state) => ({
    content: selectContent(state, Number(categoryId), Number(contentId)),
  }));
  const [manifestResponse, setManifestResponse] = useState<ManifestResponse>();
  const fetchStarted = useRef(false);
  const previousContent = useRef(content);

  useEffect(() => {
    if (!content) return;
    if (previousContent.current !== content) {
      setManifestResponse(undefined);
      fetchStarted.current = false;
    }
    previousContent.current = content;
  }, [content]);

  useEffect(() => {
    if (!content || fetchStarted.current) return;
    fetchStarted.current = true;
    if (content?.videoUrl.endsWith('.m3u8')) {
      setManifestResponse({ manifestUrl: content.videoUrl, trackingUrl: '' });
    } else if (content?.videoUrl.endsWith('.json')) {
      fetchSrcFromManifest(content).then(setManifestResponse);
    }
  }, [content]);

  return { manifestResponse, content };
};

export default usePlayerAsset;
