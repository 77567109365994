import { TvPlatform } from 'utils/types/tvPlatform';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppStore } from 'store/app';

const useSetPlatform = () => {
  const [loaded, setLoaded] = useState(false);
  const { pathname } = useLocation();
  const { setPlatform } = useAppStore(({ setPlatform }) => ({ setPlatform }));

  const setPlatformFromUrl = useCallback(() => {
    const iframe = document.querySelector('iframe');
    const link = iframe?.src || pathname;
    setPlatform(link.replace('https://', '').split('/')[1]);
  }, [pathname, setPlatform]);

  useEffect(() => {
    if (loaded) return;

    switch (true) {
      case !!window?.webOS && !!window?.webOS?.platform?.tv:
        setPlatform(TvPlatform.WEBOS);
        break;
      default:
        setPlatform(TvPlatform.BROWSER);
    }

    setLoaded(true);
  }, [loaded, pathname, setPlatform, setPlatformFromUrl]);
};

export default useSetPlatform;
