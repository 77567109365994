import cn from 'classnames';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { selectIsMagicRemoteActive } from 'store/navigation/selectors';
import { useNavigationStore } from 'store/navigation';
import {
  TvFocusableParentProps,
  TvFocusableProps,
} from 'components/TvFocusable';
import { useAppStore } from 'store/app';
import { selectCategories } from 'store/app/selectors';
import useBackController from 'hooks/useBackController';
import { navigation } from 'ConfigProvider';
import { Directions } from 'lrud';
import { ReactComponent as Logo } from 'assets/logo.svg';
import useThrottledWheel from '../../hooks/useThrottledWheel';
import useMenu from './utils/useMenu';
import styles from './index.module.scss';
import MenuItem, { MenuItemProps } from './MenuItem';
import { MenuNavigationNode } from './utils/menuNavigationNodes';

const STATIC_CATEGORIES_COUNT = 7;

interface MainMenuItem extends Partial<TvFocusableProps & MenuItemProps> {
  title: string;
  icon: string;
  parentNodeId: string;
  nodeId: string;
  id: number;
}

const Menu = () => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuWrapper = useRef<HTMLDivElement | null>(null);
  const menuItem = useRef<HTMLDivElement | null>(null);
  const menuBg = useRef<HTMLDivElement | null>(null);
  const magicRemoteActive = useNavigationStore(selectIsMagicRemoteActive);
  const categories = useAppStore(selectCategories);
  const {
    isMenuOpened,
    setIsMenuOpened,
    onEnter,
    activeMenuNodeId,
    setActiveMenuNodeId,
    lastFocusedMenuNodeId,
    setLastFocusedMenuNodeId,
  } = useMenu();
  const [activeIndex, setActiveIndex] = useState(0);

  const { scrolling } = useThrottledWheel(
    useCallback(
      ({ deltaY }) => {
        if (!isMenuOpened) return;
        const direction = deltaY > 0 ? Directions.DOWN : Directions.UP;
        navigation.go(MenuNavigationNode.MAIN_MENU, direction);
        if (
          !navigation.getNextFocusableChildInDirection(
            MenuNavigationNode.MAIN_MENU,
            direction
          )
        )
          return;
        setActiveIndex((prev) => (deltaY > 0 ? prev + 1 : prev - 1));
      },
      [isMenuOpened]
    )
  );

  const mainMenuItems: MainMenuItem[] = useMemo(() => {
    const parentProps: TvFocusableParentProps = {
      [MenuNavigationNode.MAIN_MENU]: {
        orientation: 'vertical',
        index: 0,
        parent: {
          [MenuNavigationNode.MENU]: {
            orientation: 'horizontal',
            index: 0,
            parent: {
              dashboard: undefined,
            },
          },
        },
      },
    };

    return categories.map((category, index) => ({
      title: category.name,
      icon: category.images.small_icon,
      parentNodeId: MenuNavigationNode.MAIN_MENU,
      nodeId: category.name.toLowerCase().replace(/\s+/g, '_'),
      onEnter,
      index,
      parentProps,
      id: category.id,
    }));
  }, [categories, onEnter]);

  const staticCategories = useMemo(
    () => categories.slice(0, STATIC_CATEGORIES_COUNT),
    [categories]
  );

  const onClickMenuContainerHandler = useCallback(() => {
    if (!magicRemoteActive || isMenuOpened) return;

    setIsMenuOpened(true);
  }, [isMenuOpened, magicRemoteActive, setIsMenuOpened]);

  useEffect(() => {
    if (!magicRemoteActive || !menuRef.current) return;

    const windowClickListener = (e: MouseEvent) => {
      if (!e.target) return;

      const menuOverlayClicked = menuRef.current?.contains(e.target as Node);

      if (!menuOverlayClicked && isMenuOpened) {
        setIsMenuOpened(false);
      }
    };

    document.addEventListener('click', windowClickListener);

    return () => document.removeEventListener('click', windowClickListener);
  }, [isMenuOpened, magicRemoteActive, setIsMenuOpened]);

  const onFocusHandler = useCallback(
    (nodeId: string, index: number) => {
      if (magicRemoteActive) return;
      setActiveMenuNodeId(nodeId as string);
      setActiveIndex(index);
    },
    [magicRemoteActive, setActiveMenuNodeId]
  );

  useBackController(() => {
    setIsMenuOpened(false);
  });

  useEffect(() => {
    const nodeToFind = lastFocusedMenuNodeId.replace(
      `${MenuNavigationNode.MAIN_MENU}_`,
      ''
    );

    const activeMenuIndex = mainMenuItems.findIndex(
      (item) => item.nodeId === nodeToFind
    );
    setActiveIndex(activeMenuIndex);

    if (lastFocusedMenuNodeId && isMenuOpened) {
      navigation.assignFocus(lastFocusedMenuNodeId);
    }
  }, [isMenuOpened, lastFocusedMenuNodeId, mainMenuItems]);

  useEffect(() => {
    navigation.onEvent(MenuNavigationNode.MENU, 'onInactive', () =>
      setIsMenuOpened(false)
    );
  }, [setIsMenuOpened, setLastFocusedMenuNodeId]);

  useEffect(() => {
    return () => {
      const focusedNode = navigation.previousNode?.id || '';
      if (isMenuOpened && focusedNode?.includes(MenuNavigationNode.MAIN_MENU)) {
        setLastFocusedMenuNodeId(focusedNode);
      }
    };
  }, [isMenuOpened, setLastFocusedMenuNodeId]);

  useEffect(() => {
    const menuWrapperRef = menuWrapper.current;
    const onTransitionStart = (e: TransitionEvent) => {
      if (isMenuOpened || e.target !== menuItem.current) return;
      menuBg.current?.classList.add(styles.hidden);
    };

    const onTransitionEnd = (e: TransitionEvent) => {
      if (!isMenuOpened || e.target !== menuWrapperRef) return;
      menuBg.current?.classList.remove(styles.hidden);
    };
    menuWrapperRef?.addEventListener('transitionstart', onTransitionStart);
    menuWrapperRef?.addEventListener('transitionend', onTransitionEnd);

    return () => {
      menuWrapperRef?.removeEventListener('transitionstart', onTransitionStart);
      menuWrapperRef?.removeEventListener('transitionend', onTransitionEnd);
    };
  }, [isMenuOpened]);

  return (
    <div
      ref={menuWrapper}
      className={cn(styles.root, { [styles.pointerEventsAll]: isMenuOpened })}
      onTransitionEnd={(e) => {
        e.persist();
        if (e.target === menuItem.current && isMenuOpened) {
          menuBg.current?.classList.remove(styles.hidden);
        }
      }}
    >
      <div
        ref={menuRef}
        className={cn(styles.menuWrapper, {
          [styles.menuOpenedWrapper]: isMenuOpened,
        })}
      >
        <div
          onClick={onClickMenuContainerHandler}
          className={cn(styles.menuMagicPointerHook, {
            [styles.menuOpenedMagicPointerHook]: isMenuOpened,
          })}
        />
        <div
          ref={menuBg}
          className={cn({
            [styles.menuBackgroundOpened]: isMenuOpened,
          })}
        />
        <div className={styles.menuLogoWrapper}>
          <Logo />
        </div>
        <div className={styles.menuStaticItemsWrapper}>
          {staticCategories.map((c, i) => (
            <MenuItem
              key={i + '_static'}
              isMenuOpened={isMenuOpened}
              categoryId={c.id}
              disableHoverFocus
              icon={c.images.small_icon}
              isActive={false}
              parentNodeId="menu_static"
              nodeId={`menu_static_${i}`}
              focusable={false}
              className={cn(styles.menuStaticItem, {
                [styles.hidden]: isMenuOpened,
              })}
            />
          ))}
        </div>
        <div
          className={cn(styles.menuListContainer, {
            [styles.animated]: isMenuOpened,
          })}
          style={{
            transform: `translateY(-${
              activeIndex * (menuItem.current?.clientHeight || 0)
            }px)`,
          }}
        >
          {mainMenuItems.map((item, index) => {
            const id = `${item.parentNodeId}_${item.nodeId}_${index}`;
            return (
              <MenuItem
                key={`${item.title}_${index}`}
                onEnter={onEnter}
                ref={menuItem}
                isMenuOpened={isMenuOpened}
                isActive={activeMenuNodeId === id}
                categoryId={item.id}
                disableHoverFocus={scrolling}
                onFocus={(nodeId) => onFocusHandler(nodeId, index)}
                isHidden={index < activeIndex || !isMenuOpened}
                {...item}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(Menu);
