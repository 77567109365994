import useInjectPreconnectTags from 'hooks/useInjectPreconnectTags';
import useNetworkConnectionService from 'hooks/useNetworkConnectionService';
import useWebosMagicPointer from 'hooks/useWebosMagicPointer';
import useSetPlatform from './useSetPlatform';
import useSetDeviceId from './useSetDeviceId';

interface TvConfig {
  fakeMagicRemote?: boolean;
}

const useTvConfig = ({ fakeMagicRemote }: TvConfig = {}) => {
  useInjectPreconnectTags(); // TODO REMOVE after aws updates
  useWebosMagicPointer({ fakeMagicRemote });
  useNetworkConnectionService();
  useSetPlatform();
  useSetDeviceId();
};

export default useTvConfig;
