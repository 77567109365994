import cn from 'classnames';
import { ReactNode, memo } from 'react';
import TvFocusable, { TvFocusableProps } from 'components/TvFocusable';
import { NodeId } from 'lrud';
import styles from './index.module.scss';

export type ButtonVariant = 'base' | 'secondary';

export interface ButtonProps {
  variant?: ButtonVariant;
  tvFocusableProps: Omit<TvFocusableProps, 'children'>;
  children?: ReactNode | string;
  className?: string;
  icon?: ReactNode;
}

export const Button = ({
  variant = 'base',
  tvFocusableProps,
  children,
  className,
  icon,
}: ButtonProps) => {
  const {
    onBlur: onBlurProps,
    onFocus: onFocusProps,
    onClick,
    nodeId,
  } = tvFocusableProps;
  const onFocusHandler = () => onFocusProps?.(nodeId);
  const onBlurHandler = () => onBlurProps?.(nodeId);
  const onClickHandler = (id: NodeId) => onClick?.(id);

  return (
    <TvFocusable
      activeClass={styles.active}
      {...tvFocusableProps}
      onClick={onClickHandler}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      nodeId={nodeId}
    >
      <button
        key={nodeId}
        data-testid="button"
        className={cn(styles.root, className, {
          [styles.base]: variant === 'base',
          [styles.secondary]: variant === 'secondary',
        })}
      >
        <span className={styles.buttonIcon}>{icon}</span>
        {children}
      </button>
    </TvFocusable>
  );
};

export default memo(Button);
