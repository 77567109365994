import api from 'api';
import { BaseCategory, Category } from 'types/category';

export const getCategories = async () => {
  const { data } = await api().get<BaseCategory[]>('/content/categories');

  return data;
};

export const getCategoryById = async (id: number) => {
  const { data } = await api().get<Category>(`/content/categories/${id}`);

  return data;
};
