import { MoveToState } from 'utils/types/locationState';
import { create } from 'zustand';

type ImageObserver = {
  observe: (element: HTMLImageElement) => void;
  unobserve: (element: HTMLImageElement) => void;
} | null;

export interface Navigation {
  activeCarouselIndex: number;
  setActiveCarouselIndex: (index: number) => void;
  isJumbotronActive: boolean;
  isJumbotronExists: boolean;
  setJumbotronActive: (active: boolean) => void;
  isMoving: boolean;
  setIsMoving: (isMoving: boolean) => void;
  continueWatchingIsEmpty: boolean;
  setContinueWatchingIsEmpty: (isEmpty: boolean) => void;
  imageObserver: ImageObserver;
  setImageObserver: (observer: ImageObserver) => void;
  canCloseDrawer: boolean;
  setCanCloseDrawer: (canClose: boolean) => void;
  magicRemoteActive: boolean;
  setMagicRemoteActive: (active: boolean) => void;
  setIsJumbotronExists: (isJumbotronExists: boolean) => void;
  view: any[];
  addView: (view: any) => void;
  setView: (view: any) => void;
  returnState: MoveToState;
  setReturnState: (returnState: MoveToState) => void;
  resetReturnState: () => void;
  resetNavigationStore: () => void;
}

export const useNavigationStore = create<Navigation>((set) => ({
  view: [],
  addView: (view) => set((state) => ({ view: [...state.view, ...view] })),
  setView: (view) => set({ view }),
  activeCarouselIndex: 0,
  setActiveCarouselIndex: (index: number) =>
    set({ activeCarouselIndex: index }),
  isJumbotronActive: false,
  setJumbotronActive: (active) => set({ isJumbotronActive: active }),
  isJumbotronExists: false,
  isMoving: false,
  setIsMoving: (isMoving) =>
    set((state) => (state.isMoving !== isMoving ? { isMoving } : state)),
  continueWatchingIsEmpty: false,
  setContinueWatchingIsEmpty: (isEmpty) =>
    set({ continueWatchingIsEmpty: isEmpty }),
  imageObserver: null,
  setImageObserver: (observer: ImageObserver) =>
    set({ imageObserver: observer }),
  canCloseDrawer: true,
  setCanCloseDrawer: (canClose) => set({ canCloseDrawer: canClose }),
  magicRemoteActive: false,
  setMagicRemoteActive: (active) => set({ magicRemoteActive: active }),
  returnState: {
    returnCollectionIndex: 0,
    returnContentIndex: 0,
  },
  setReturnState: (returnState: MoveToState) =>
    set((state) => ({ ...state, returnState })),
  resetReturnState: () =>
    set(() => ({
      returnState: { returnCollectionIndex: 0, returnContentIndex: 0 },
    })),
  setIsJumbotronExists: (isJumbotronExists) => set({ isJumbotronExists }),
  resetNavigationStore: () =>
    set({
      view: [],
      activeCarouselIndex: 0,
      continueWatchingIsEmpty: false,
      isJumbotronActive: true,
    }),
}));
