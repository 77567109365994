import { useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import { usePlayerStore } from '../store';
import { AVODParsedInfo } from '../../../types/player';
import { navigation } from '../../../ConfigProvider';

//note: 'disableAds' prop are for development purposes only
const useAVODTracker = (playerInitialized = false, disableAds = false) => {
  const { playerRef, currentTime, actions } = usePlayerStore(
    ({ playerRef, currentTime, actions }) => ({
      playerRef,
      currentTime,
      actions,
    })
  );
  const playbackState = usePlayerStore((state) => state.state);
  const initTrackingDataRef = useRef<AVODParsedInfo[]>([]);
  const isAdFinished = useRef(false);
  const sendBeacons = async (beaconUrls: string[]) =>
    Promise.allSettled(
      beaconUrls.map((url) => axios.get(url, { withCredentials: false }))
    );
  const handleBlockNavigation = useCallback(
    async (currentAd?: AVODParsedInfo) => {
      if (currentAd && playbackState !== 'adPlaying') {
        isAdFinished.current = false;
        if (playbackState === 'seeking') {
          await playerRef?.getMediaElement()?.play();
        }
        actions.setAdPlaying();
        navigation.setNavigationBlocked(true);
      } else if (!isAdFinished.current && !currentAd) {
        isAdFinished.current = true;
        actions.play();
        navigation.setNavigationBlocked(false);
      }
    },
    [actions, playbackState, playerRef]
  );
  const handleSendBeacon = useCallback(
    async (currentTime: number, currentAd?: AVODParsedInfo) => {
      const currentEvent = currentAd?.events.find(
        ({ startTimeInSeconds, fulfilled }) =>
          currentTime >= startTimeInSeconds && !fulfilled
      );
      if (!currentEvent) return;
      currentEvent.fulfilled = true;
      await sendBeacons(currentEvent.beaconUrls || []);
    },
    []
  );

  useEffect(() => {
    if (!playerRef || disableAds) return;
    const currentAd = initTrackingDataRef.current.find(
      ({ adStart, adEnd }) => currentTime >= adStart && currentTime <= adEnd
    );
    handleBlockNavigation(currentAd);
    handleSendBeacon(currentTime, currentAd);
  }, [
    playerRef,
    currentTime,
    handleSendBeacon,
    handleBlockNavigation,
    disableAds,
  ]);

  useEffect(() => {
    return () => {
      if (!playerInitialized) return;
      initTrackingDataRef.current = [];
    };
  }, [playerInitialized]);

  return { initTrackingDataRef };
};

export default useAVODTracker;
