import Button from 'components/Button';
import { TvFocusableParentProps } from 'components/TvFocusable';
import useExitApp from 'hooks/useExitApp';
import { useUserStore } from 'store/user';
import { ReactComponent as Logo } from 'assets/logo.svg';
import styles from './index.module.scss';

const termsNodeId = 'termsOfUse';
const Terms = () => {
  const exit = useExitApp();
  const setTermsAccepted = useUserStore(
    ({ setTermsAccepted }) => setTermsAccepted
  );

  const buttonParentProps: TvFocusableParentProps = {
    [termsNodeId]: {
      orientation: 'horizontal',
    },
  };

  const onTermsAgree = () => setTermsAccepted(true);
  const onTermsDisagree = () => exit();

  return (
    <div className={styles.pageWrapper}>
      <Logo className={styles.pageLogo} />

      <div className={styles.termsWrapper}>
        <span className={styles.termsHeader}>Terms of Use</span>

        <div className={styles.termsDescription}>
          To read the full version of our {<br />} Privacy Policy & Terms of Use
          {<br />}please visit www.dudeperfect.com/terms
        </div>

        <div className={styles.termsNote}>
          This Application uses 3rd party services to help us determine app
          performance and popular titles.
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.button}
            variant="secondary"
            tvFocusableProps={{
              parentNodeId: termsNodeId,
              nodeId: 'disagree',
              onClick: onTermsDisagree,
              parentProps: buttonParentProps,
            }}
          >
            Disagree
          </Button>

          <Button
            className={styles.button}
            variant="secondary"
            tvFocusableProps={{
              parentNodeId: termsNodeId,
              nodeId: 'agree',
              onClick: onTermsAgree,
              assignFocus: true,
              parentProps: buttonParentProps,
            }}
          >
            Agree
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Terms;
