import { useEffect, useMemo, useState } from 'react';
import { useAppStore } from 'store/app';
import { selectPlatform } from 'store/app/selectors';
import { TvPlatform } from 'utils/types/tvPlatform';

const useTvSystemVersion = () => {
  const platform = useAppStore(selectPlatform);
  const [platformVersion, setPlatformVersion] = useState('');

  const versionFetchMethods = useMemo(
    () => ({
      [TvPlatform.WEBOS]: () => {
        window.webOS.service.request(
          'luna://com.webos.service.tv.systemproperty',
          {
            method: 'getSystemInfo',
            parameters: {
              keys: ['modelName', 'UHD', 'sdkVersion'],
            },
            onComplete: function ({
              UHD,
              sdkVersion,
              modelName,
              returnValue,
            }: any) {
              if (!returnValue) return;
              const platformData = [
                ...[UHD ? 'UHD' : 'HD'],
                sdkVersion,
                modelName,
              ].join(', ');

              setPlatformVersion(platformData);
            },
          }
        );
      },
      [TvPlatform.BROWSER]: () => {
        const chromeVersion =
          /Chrome\/([0-9.]+)/.exec(window.navigator.userAgent)?.[1] || '';
        setPlatformVersion(chromeVersion);
      },
    }),
    [setPlatformVersion]
  );

  useEffect(() => {
    if (platform && !platformVersion) {
      versionFetchMethods[platform as TvPlatform]?.();
    }
  }, [platform, platformVersion, versionFetchMethods]);

  return platformVersion;
};

export default useTvSystemVersion;
