import { useHistory, useParams } from 'react-router-dom';
import { RouteIdParams } from 'utils/types/navigation';
import { useAppStore } from 'store/app';
import { selectCategoryById } from 'store/app/selectors';
import PosterList from 'components/PosterList';
import { NAVIGATION_PARENT_NODES } from 'types/navigation';
import { useCallback } from 'react';
import useBackController from 'hooks/useBackController';
import { useNavigationStore } from '../../store/navigation';
import styles from './index.module.scss';

const Listing = () => {
  const { push } = useHistory();
  const { id } = useParams<RouteIdParams>();
  const { resetReturnState } = useNavigationStore((state) => ({
    resetReturnState: state.resetReturnState,
  }));
  const { category } = useAppStore((state) => ({
    category: selectCategoryById(state, Number(id)),
  }));

  const onBack = useCallback(() => {
    resetReturnState();
    push(`/`);
  }, [push, resetReturnState]);

  useBackController(onBack);

  if (!category) {
    return null;
  }

  return (
    <div className={styles.listingPageWrapper}>
      <div className={styles.listingPageHeader}>
        <img
          src={category.images.icon}
          alt={category.name}
          className={styles.listingPageIcon}
        />
        <span className={styles.listingPageTitle}>{category.name}</span>
      </div>

      <PosterList
        key={id} //Allows for proper unmount of nodes in poster list
        contents={category.contents}
        parentNodeId={NAVIGATION_PARENT_NODES.LISTING_NODE_ID}
        categoryId={category.id}
      />
    </div>
  );
};

export default Listing;
