import { ReactNode, memo, useLayoutEffect } from 'react';
import { navigation } from 'ConfigProvider';
import LinearWrapper from 'components/LinearWrapper';
import styles from './index.module.scss';

interface ModalProps {
  parentNodeId: string;
  isOpen: boolean;
  children: ReactNode;
  orientation?: 'horizontal' | 'vertical';
}

const Modal = ({
  parentNodeId,
  isOpen,
  children,
  orientation = 'vertical',
}: ModalProps) => {
  useLayoutEffect(() => {
    navigation.registerParentNodeOutOfBox(parentNodeId, orientation);
  }, [orientation, parentNodeId]);

  if (!isOpen) return null;

  return <LinearWrapper className={styles.root}>{children}</LinearWrapper>;
};

export default memo(Modal);
