export interface AVODResponse {
  avails: {
    ads: AVODInfo[];
    durationInSeconds: number;
    startTimeInSeconds: number;
    availId: string;
  }[];
}

export enum AVODEventType {
  IMPRESSION = 'impression',
  START = 'start',
  FIRST_QUARTILE = 'firstQuartile',
  MIDPOINT = 'midpoint',
  THIRD_QUARTILE = 'thirdQuartile',
  COMPLETE = 'complete',
}

export interface AVODTrackingEvent {
  beaconUrls: string[];
  duration: number;
  durationInSeconds: number;
  eventId: string;
  eventType: AVODEventType;
  startTime: number;
  startTimeInSeconds: number;
}

export interface AVODInfo {
  duration: number;
  durationInSeconds: number;
  startTime: number;
  startTimeInSeconds: number;
  trackingEvents: AVODTrackingEvent[];
  vastAdId: string;
}

export interface AVODParsedEvent {
  startTimeInSeconds: number;
  beaconUrls: string[];
  eventType: AVODEventType;
  eventId: string;
  fulfilled?: boolean;
}

export interface AVODParsedInfo {
  adStart: number;
  adEnd: number;
  events: AVODParsedEvent[];
}

export enum PlayerNodeParentId {
  CONTROLS = 'player_controls',
  TRACK = 'player_track',
  SKIP_CONTROLS = 'player_skip_controls',
  TRACK_CONTROLS = 'player_track_controls',
  TOP_CONTROLS = 'player_top_controls',
}

export enum PlayerNodeChildId {
  BACK_BUTTON = 'player_back_button',
  NEXT_EPISODE_BUTTON = 'player_next_episode_button',
  EPISODES_BUTTON = 'player_episodes_button',
  SEARCH_BUTTON = 'player_search_button',
  SEEKBAR_CONTROL = 'player_seekbar_control',
  PLAY_CONTROL = 'play_pause_control',
  TRACKS_BUTTON = 'player_tracks_settings_button',
  QUALITIES_BUTTON = 'player_qualities_button',
  CURRENT_PLAYING_COLLECTION = 'player_current_playing_collection',
}

export enum VideoHtmlEvent {
  PAUSE = 'pause',
  PLAY = 'play',
  ENDED = 'ended',
  PLAYING = 'playing',
  CANPLAY = 'canplay',
  CANPLAYTHROUGH = 'canplaythrough',
  WAITING = 'waiting',
  TIMEUPDATE = 'timeupdate',
  ERROR = 'error',
  LOADEDMETADATA = 'loadedmetadata',
}

export enum ShakaEvent {
  BUFFERING = 'buffering',
  ERROR = 'error',
  LOADED = 'loaded',
}
export interface ShakaError {
  detail: {
    severity: number;
    category: number;
    code: number;
    data?: { [key: string]: any };
  };
}

export interface ShakaPlayerEvent extends Event {
  buffering?: boolean;
}

export enum StreamType {
  DASH = 'DASH',
  HLS = 'HLS',
}

export const PLAYER_NODE_ID = 'player';
