import { useEffect } from 'react';
import {
  MediaKeyButton,
  getRemoteControllerKey,
} from 'utils/remoteControllerHelpers';

const useBackController = (backActionCallback: (e: KeyboardEvent) => void) => {
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const isBackKey = getRemoteControllerKey(e) === MediaKeyButton.BACK;

      if (!isBackKey) return;
      return backActionCallback(e);
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [backActionCallback]);
};

export default useBackController;
