import CircularProgress from 'components/CircularProgress';
interface ProgressIndicatorProps {
  variant: 'circular' | 'linear';
  className?: string;
}

const ProgressIndicator = ({ variant, className }: ProgressIndicatorProps) => {
  switch (variant) {
    case 'circular':
      return <CircularProgress className={className} />;
    default:
      return null;
  }
};

export default ProgressIndicator;
