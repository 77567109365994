import { useCallback, useEffect, useRef } from 'react';
import shaka from 'shaka-player';
import { AxiosError } from 'axios';
import { ShakaError, ShakaEvent, ShakaPlayerEvent } from '../types';
import { usePlayerStore } from '../store';
import { selectPlayerRef } from '../store/selectors';
import { VideoHtmlEvent } from '../../../types/player';

interface ShakaEventsProviderProps {
  onError?: (e: ShakaError | AxiosError) => void;
  onPlaying?: () => void;
}

const shakaEventManager = new shaka.util.EventManager();

const useShakaEvents = ({ onError, onPlaying }: ShakaEventsProviderProps) => {
  const eventManager = useRef<shaka.util.EventManager>(shakaEventManager);
  const { playerRef, playbackActions } = usePlayerStore((state) => ({
    playerRef: selectPlayerRef(state),
    playbackActions: state.actions,
  }));

  const onBufferingStart = useCallback(() => {
    playbackActions.buffer();
  }, [playbackActions]);

  const onBufferingEnd = useCallback(() => {
    onPlaying?.();
    playbackActions.play();
  }, [onPlaying, playbackActions]);

  useEffect(() => {
    if (!playerRef) return;
    const eventManagerRef = eventManager.current;
    const onShakaBuffering = (e: ShakaPlayerEvent) =>
      e.buffering ? onBufferingStart() : onBufferingEnd();
    eventManagerRef.listen(playerRef, ShakaEvent.BUFFERING, onShakaBuffering);

    return () => {
      eventManagerRef.unlisten(
        playerRef,
        ShakaEvent.BUFFERING,
        onShakaBuffering
      );
    };
  }, [playerRef, onBufferingStart, onBufferingEnd]);

  useEffect(() => {
    if (!playerRef) return;
    const eventManagerRef = eventManager.current;

    const listener = (e: any) => {
      onError?.(e as ShakaError);
    };
    eventManagerRef.listen(playerRef, ShakaEvent.ERROR, listener);

    return () => {
      eventManagerRef.unlisten(playerRef, ShakaEvent.ERROR, listener);
    };
  }, [onError, playerRef]);

  useEffect(() => {
    if (!playerRef) return;
    const videoEl = playerRef.getMediaElement();
    const handlePlaying = () => {
      onPlaying?.();
    };

    videoEl?.addEventListener(VideoHtmlEvent.PLAYING, handlePlaying);

    return () => {
      videoEl?.removeEventListener(VideoHtmlEvent.PLAYING, handlePlaying);
    };
  }, [onPlaying, playerRef]);

  useEffect(() => {
    const eventManagerRef = eventManager.current;
    return () => eventManagerRef.removeAll();
  }, [playerRef]);
};

export default useShakaEvents;
