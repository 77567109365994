import TvFocusable from 'components/TvFocusable';
import { ReactComponent as PlayButton } from 'assets/icons/play-button.svg';
import { ReactComponent as PauseButton } from 'assets/icons/pause_button.svg';
import useAutoSeek from 'components/PlayerOverlay/hooks/useAutoSeek';
import useMoveSeekTime from 'components/PlayerOverlay/hooks/useMoveSeekTime';
import { navigation } from 'ConfigProvider';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { usePlayerStore } from 'components/Player/store';
import {
  selectControlsIsHidden,
  selectCurrentTime,
  selectIsBuffering,
  selectPlaybackState,
  selectPlayerActions,
  selectPlayerRef,
} from 'components/Player/store/selectors';
import useSeekIntervals from 'components/PlayerOverlay/hooks/useSeekIntervals';
import useSeekEvents from 'components/PlayerOverlay/hooks/useSeekEvents';
import cn from 'classnames';
import { PlayerNodeChildId, PlayerNodeParentId } from 'types/player';
import SeekSpeedIndicator from '../SeekSpeedIndicator';
import TimeControl from '../TimeControl';
import SeekBar from '../SeekBar';
import CurrentPlayingCollection from '../CurrentPlayingCollection';
import styles from './index.module.scss';

interface ControlsProps {
  isOverlayVisible: boolean;
  categoryId?: string;
  contentId?: string;
}

const Controls = ({
  isOverlayVisible,
  contentId,
  categoryId,
}: ControlsProps) => {
  const {
    playerReference,
    playerActions,
    playbackState,
    currentTime,
    controlsHidden,
    isBuffering,
  } = usePlayerStore((state) => ({
    playerReference: selectPlayerRef(state),
    playerActions: selectPlayerActions(state),
    playbackState: selectPlaybackState(state),
    currentTime: selectCurrentTime(state),
    controlsHidden: selectControlsIsHidden(state),
    isBuffering: selectIsBuffering(state),
  }));
  const videoElement = playerReference?.getMediaElement();
  const { cancelCurrentTimeInterval, setCurrentTimeInterval } =
    useSeekIntervals();
  const { moveTrack, onTrackIndicatorClick, clearSeekTimeout, setSeekedTime } =
    useMoveSeekTime({
      cancelTimeInterval: cancelCurrentTimeInterval,
      setTimeInterval: setCurrentTimeInterval,
    });
  const { autoSeek, setAutoSeek } = useAutoSeek({
    videoElement,
    seekFn: moveTrack,
  });
  const onTrackClickHandler = (e: any) => {
    if (!videoElement || playbackState === 'buffering') return;
    e.persist();
    const targetWidth = e.target.clientWidth;
    const clickPosition = e.clientX - e.target?.getBoundingClientRect()?.left;
    const percent = (clickPosition * 100) / targetWidth;
    // note: percent to seconds
    const seekedTime = (percent / 100) * videoElement.duration;
    videoElement.pause();
    videoElement.currentTime = seekedTime;
    playerActions.setCurrentTime(seekedTime);
    videoElement.play();
  };

  const playPauseHandler = useCallback(async () => {
    if (navigation.blocked) return;
    const videoElement = playerReference?.getMediaElement();
    clearSeekTimeout();
    setAutoSeek({
      direction: null,
      speed: 0,
    });
    cancelCurrentTimeInterval();
    if (!videoElement?.src || playbackState === 'buffering') return;
    if (playbackState === 'playing') {
      videoElement.pause();
    } else if (['paused', 'seeking'].includes(playbackState)) {
      playerActions.buffer();
      await videoElement.play();
    }
  }, [
    playerReference,
    clearSeekTimeout,
    setAutoSeek,
    cancelCurrentTimeInterval,
    playbackState,
    playerActions,
  ]);

  useSeekEvents({
    setAutoSeek,
    moveTrack,
    setCurrentTimeInterval,
    cancelCurrentTimeInterval,
    playPauseHandler,
    setSeekedTime,
    clearSeekTimeout,
  });

  useEffect(() => {
    if (isOverlayVisible && !isBuffering) {
      navigation.assignFocus(
        `${PlayerNodeParentId.CONTROLS}_${PlayerNodeChildId.PLAY_CONTROL}`
      );
    }
  }, [isOverlayVisible, isBuffering]);

  useLayoutEffect(() => {
    videoElement?.addEventListener('click', playPauseHandler);
    setCurrentTimeInterval();

    return () => {
      videoElement?.removeEventListener('click', playPauseHandler);
      cancelCurrentTimeInterval();
    };
  }, [
    cancelCurrentTimeInterval,
    playPauseHandler,
    setCurrentTimeInterval,
    videoElement,
  ]);

  return (
    <div className={styles.root}>
      {!isBuffering ? (
        <TvFocusable
          parentNodeId={PlayerNodeParentId.CONTROLS}
          nodeId={PlayerNodeChildId.PLAY_CONTROL}
          onClick={playPauseHandler}
          index={0}
          assignFocus={isOverlayVisible}
          activeClass={styles.iconActive}
          className={styles.iconFocusable}
        >
          <div className={styles.controlWrapper}>
            {['paused', 'seeking'].includes(playbackState) ? (
              <PlayButton data-testid="play-button" className={styles.icon} />
            ) : (
              <PauseButton data-testid="pause-button" className={styles.icon} />
            )}
          </div>
        </TvFocusable>
      ) : null}
      <div className={styles.trackWrapper}>
        <div className={cn({ [styles.controlsHidden]: controlsHidden })}>
          <SeekSpeedIndicator speed={autoSeek.speed} />
          <TimeControl
            visible
            currentTime={currentTime}
            duration={videoElement?.duration || 0}
          >
            <SeekBar
              seekTime={currentTime}
              seeking={playbackState === 'seeking'}
              duration={videoElement?.duration || 0}
              onClick={onTrackClickHandler}
              onIndicatorClick={onTrackIndicatorClick}
              onMoveTrack={moveTrack}
              seekSpeed={autoSeek.speed}
            />
          </TimeControl>
        </div>

        <CurrentPlayingCollection
          categoryId={Number(categoryId)}
          currentContentId={Number(contentId)}
        />
      </div>
    </div>
  );
};

export default Controls;
