import { useEffect, useRef, useState } from 'react';
import shaka from 'shaka-player';
import muxjs from 'mux.js';
import axios from 'axios';
import { usePlayerStore } from '../store';
import { selectPlayerActions, selectPlayerRef } from '../store/selectors';
import { ManifestResponse } from '../../../actions/playback';
import { AVODParsedInfo, AVODResponse } from '../../../types/player';
import { parseAVODResponse } from '../utils';
import { navigation } from '../../../ConfigProvider';
import { ShakaError } from '../types';
import useAVODTracker from './useAVODTracker';

const initPlayer = async (
  { trackingUrl, manifestUrl }: ManifestResponse,
  videoEl: HTMLVideoElement | null,
  containerEl: HTMLDivElement | null
): Promise<{
  player?: shaka.Player;
  trackingData?: AVODParsedInfo[];
  error: ShakaError | null;
}> => {
  try {
    window.muxjs = muxjs;
    shaka.polyfill.installAll();
    const player: shaka.Player = new shaka.Player(videoEl);
    player?.setVideoContainer(containerEl);
    player.configure({
      abr: {
        restrictToScreenSize: true,
      },
      streaming: {
        rebufferingGoal: 3,
        bufferingGoal: 5,
        bufferBehind: 10,
      },
      manifest: {
        retryParameters: {
          timeout: 10000,
          maxAttempts: 100,
          baseDelay: 500,
        },
        hls: {
          ignoreTextStreamFailures: true,
          ignoreImageStreamFailures: true,
          ignoreManifestProgramDateTime: true,
          useSafariBehaviorForLive: false,
          // TODO: uncomment sequence mode when shaka issue is resolved
          // https://github.com/shaka-project/shaka-player/issues/5374
          // sequenceMode: false,
        },
      },
    });
    await player?.load(manifestUrl);

    if (trackingUrl) {
      const { data: trackingValues } = await axios.get<AVODResponse>(
        trackingUrl
      );
      const parsedTrackingData = parseAVODResponse(trackingValues);
      return { player, trackingData: parsedTrackingData, error: null };
    }

    return { player, trackingData: undefined, error: null };
  } catch (e) {
    console.error(e);
    return { player: undefined, trackingData: undefined, error: e };
  }
};

interface InitPlayerParams {
  manifestResponse?: ManifestResponse;
  videoEl: HTMLVideoElement | null;
  containerEl: HTMLDivElement | null;
}
const useInitPlayer = ({
  manifestResponse,
  videoEl,
  containerEl,
}: InitPlayerParams) => {
  const initialized = useRef(false);
  const playerActions = usePlayerStore(selectPlayerActions);
  const playerRef = usePlayerStore(selectPlayerRef);
  const [playerError, setPlayerError] = useState<ShakaError | null>();

  const { initTrackingDataRef } = useAVODTracker(initialized.current);

  useEffect(() => {
    if (!shaka.Player.isBrowserSupported()) {
      //todo show an error page;
      return;
    }
    if (
      !videoEl ||
      !containerEl ||
      !manifestResponse?.manifestUrl ||
      initialized.current
    )
      return;
    initialized.current = true;
    initPlayer(manifestResponse, videoEl, containerEl).then(
      ({ player, trackingData, error }) => {
        playerActions.setPlayerRef(player);
        if (player) {
          if (trackingData) {
            initTrackingDataRef.current = trackingData;
          }
        } else {
          setPlayerError(error);
          console.error('Could not init player');
        }
      }
    );
  }, [
    containerEl,
    playerActions,
    manifestResponse,
    videoEl,
    initTrackingDataRef,
  ]);

  useEffect(() => {
    return () => {
      if (!playerRef) return;
      playerRef.destroy();
      initialized.current = false;
      playerActions.reset();
      navigation.setNavigationBlocked(false);
    };
  }, [playerActions, playerRef, manifestResponse]);

  return { error: playerError };
};

export default useInitPlayer;
