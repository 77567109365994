import { useCallback } from 'react';
import { useMenuStore } from 'store/menu';
import {
  selectActiveMenuNodeId,
  selectIsMenuOpened,
} from 'store/menu/selectors';

const useMenu = () => {
  const {
    isMenuOpened,
    setIsMenuOpened,
    activeMenuNodeId,
    setActiveMenuNodeId,
    lastFocusedMenuNodeId,
    setLastFocusedMenuNodeId,
  } = useMenuStore((state) => ({
    isMenuOpened: selectIsMenuOpened(state),
    setIsMenuOpened: state.setIsMenuOpened,
    activeMenuNodeId: selectActiveMenuNodeId(state),
    setActiveMenuNodeId: state.setActiveMenuNodeId,
    lastFocusedMenuNodeId: state.lastFocusedMenuNodeId,
    setLastFocusedMenuNodeId: state.setLastFocusedMenuNodeId,
  }));

  const onEnter = useCallback(
    (nodeId: string | number) => {
      setIsMenuOpened(true);
    },
    [setIsMenuOpened]
  );

  return {
    onEnter,
    isMenuOpened,
    setIsMenuOpened,
    activeMenuNodeId,
    setActiveMenuNodeId,
    lastFocusedMenuNodeId,
    setLastFocusedMenuNodeId,
  };
};

export default useMenu;
