import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
} from 'react';
import TvFocusable from 'components/TvFocusable';
import { ReactComponent as PlayIcon } from 'assets/icons/carousel-play.svg';
import cn from 'classnames';
import { Content } from 'types/content';
import { useMenuStore } from 'store/menu';
import { selectIsMenuOpened } from 'store/menu/selectors';
import useRatioStrategy from 'hooks/useRatioStrategy';
import styles from './index.module.scss';
interface PosterProps {
  data: Content;
  parentNodeId: string;
  nodeId: string;
  onClick?: (asset: Content, nodeId: string) => void;
  onFocus?: (nodeId: string) => void;
  assignFocus?: boolean;
  index?: number;
  isActive?: boolean;
  className?: string;
  style?: CSSProperties;
  options?: {
    disableFrame?: boolean;
    disableHoverFocus?: boolean;
  };
}

const Poster = (
  {
    data,
    parentNodeId,
    onClick,
    onFocus,
    nodeId,
    assignFocus,
    index = 0,
    isActive,
    className,
    style,
    options: { disableFrame = false, disableHoverFocus = true } = {},
  }: PosterProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const isMenuOpened = useMenuStore(selectIsMenuOpened);
  const { posterHeight, posterWidth } = useRatioStrategy();

  const onClickHandler = useCallback(
    (nodeId: string) => onClick?.(data, nodeId),
    [data, onClick]
  );

  //When menu is opened poster shouldn't be styled for active
  const posterActive = isActive && !isMenuOpened;

  return (
    <TvFocusable
      parentNodeId={parentNodeId}
      nodeId={nodeId}
      onClick={onClickHandler}
      onFocus={onFocus}
      index={index}
      assignFocus={assignFocus}
      className={cn(className, styles.posterWrapper, {
        [styles.activePoster]: posterActive,
      })}
      style={{
        ...style,
        height: posterHeight,
        minWidth: posterWidth,
        width: posterWidth,
        backgroundImage: `url(${data.imageUrl})`,
      }}
      disableHoverFocus={disableHoverFocus}
    >
      <div
        ref={ref}
        className={cn(styles.poster)}
        style={{ height: posterHeight, minWidth: posterWidth }}
      >
        <span
          className={cn(styles.frame, {
            [styles.displayFrame]: posterActive && !disableFrame,
          })}
          style={{
            height: posterHeight,
            minWidth: posterWidth,
            width: posterWidth,
          }}
        />

        <div className={styles.posterTitleWrapper}>
          <PlayIcon />
          <span className={styles.posterTitle}>{data.title}</span>
        </div>
      </div>
    </TvFocusable>
  );
};

const PosterForwardRef = forwardRef(Poster);

export default memo(PosterForwardRef);
