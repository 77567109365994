import { create } from 'zustand';
import { TvPlatform } from 'utils/types/tvPlatform';
import { Category } from 'types/category';
import { persist } from 'zustand/middleware';

export interface AppState {
  appSettings: {
    isNetworkConnected: boolean;
    deviceId: string;
    platform?: TvPlatform | string;
    ttsEnabled: boolean;
    ccEnabled: boolean;
  };
  appState: {
    categoriesLoading: boolean;
  };
  categories: Category[];
  apiBaseUrl: string;
  setApiBaseUrl: (payload: string) => void;
  setIsNetworkConnected: (payload: boolean) => void;
  setCategories: (payload: Category[]) => void; //cat with content
  setPlatform: (payload: TvPlatform | string) => void;
  setDeviceUid: (payload: string) => void;
  setCategoriesLoading: (payload: boolean) => void;
  resetAppStore: () => void;
}

export const useAppStore = create<AppState>()(
  persist(
    (set) => ({
      categories: [],
      apiBaseUrl: '',
      appSettings: {
        isNetworkConnected: true,
        ttsEnabled: false,
        ccEnabled: false,
        deviceId: '',
        platform: '',
      },
      appState: {
        categoriesLoading: true,
      },
      setIsNetworkConnected: (isNetworkConnected: boolean) => {
        set((state) => ({
          appSettings: {
            ...state.appSettings,
            isNetworkConnected,
          },
        }));
      },
      setPlatform: (platform: TvPlatform | string) => {
        set((state) => ({
          appSettings: {
            ...state.appSettings,
            platform,
          },
        }));
      },
      setDeviceUid: (deviceId: string) => {
        set((state) => ({
          appSettings: {
            ...state.appSettings,
            deviceId,
          },
        }));
      },

      setApiBaseUrl: (apiBaseUrl: string) => {
        set(() => ({
          apiBaseUrl,
        }));
      },

      setCategories: (categories: Category[]) => {
        set(() => ({
          categories,
        }));
      },
      setCategoriesLoading: (categoriesLoading: boolean) => {
        set((state) => ({
          appState: {
            ...state.appState,
            categoriesLoading,
          },
        }));
      },
      //Don't reset information about device in app settings property as it's static value
      resetAppStore: () => {
        set(() => ({
          apiBaseUrl: '',
          categories: [],
        }));
      },
    }),
    {
      name: 'app-settings',
      partialize: (state) => ({
        appSettings: {
          deviceId: state.appSettings.deviceId,
        },
      }),
    }
  )
);
