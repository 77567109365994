import api from 'api';

interface ConsoleMethods {
  log: () => void;
  warn: () => void;
  error: () => void;
  info: () => void;
  trace: () => void;
}

type LogContent = string | { [key: string]: any };
const MAX_LOGS_LENGTH = 50;
/* eslint-disable */
const CONSOLE_METHODS: ConsoleMethods = {
  log: console.log,
  warn: console.warn,
  error: console.error,
  info: console.info,
  trace: console.trace,
};
/* eslint-enable */

export default class ConsoleOverride {
  logEvent: Event;
  log: ((...args: any[]) => void) | undefined;
  warn: ((...args: any[]) => void) | undefined;
  error: ((...args: any[]) => void) | undefined;
  info: ((...args: any[]) => void) | undefined;
  trace: ((...args: any[]) => void) | undefined;

  constructor() {
    this.logEvent = new Event('log');
    this.init();
  }

  init() {
    window.logs = [];
    this.createConsoleMethods();
    this.overrideConsoleMethods();
    this.registerLoggingListeners();
  }

  get timestamp(): string {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }

  createConsoleMethods() {
    Object.keys(CONSOLE_METHODS).forEach((method) => {
      this[method as keyof ConsoleMethods] = (...logs: LogContent[]) => {
        logs.forEach((log: LogContent) => {
          const newLog =
            typeof log === 'object' ? JSON.stringify(log) : String(log);
          window.dispatchEvent(this.logEvent);
          window.logs.push({
            type: method,
            log: newLog,
            timestamp: this.timestamp,
          });

          if (window.logs.length > MAX_LOGS_LENGTH) {
            window.logs.shift();
          }
        });
      };
    });
  }

  overrideConsoleMethods() {
    Object.keys(CONSOLE_METHODS).forEach((method) => {
      // eslint-disable-next-line
      console[method as keyof ConsoleMethods] = (...args: LogContent[]) => {
        const methodName = CONSOLE_METHODS[method as keyof ConsoleMethods];
        methodName.call(console, ...(args as []));
        this[method as keyof ConsoleMethods]?.(...args);
      };
    });

    api().interceptors.request.use(
      (config) =>
        new Promise((resolve) => {
          this.info?.(
            `Request: ${config.url}, type: ${config.method?.toUpperCase()}`
          );
          resolve(config);
        })
    );

    api().interceptors.response.use(
      (response) =>
        new Promise((resolve) => {
          this.info?.(`Response status: ${response.status}`);
          this.info?.(
            `Response data is empty: ${[undefined, null, ''].includes(
              response.data
            )}`
          );
          resolve(response);
        }),
      (error) => {
        this.error?.(
          `Error status: ${error.status}, message: ${error.message}`
        );
      }
    );
  }

  registerLoggingListeners() {
    window.addEventListener('keydown', this.onKeyDown.bind(this));
  }

  onKeyDown({ key, code }: KeyboardEvent) {
    this.log?.(`You pressed ${key}, code: ${code}`);
  }
}
