import { ForwardedRef, forwardRef } from 'react';
import TvFocusable, { TvFocusableProps } from 'components/TvFocusable';
import cn from 'classnames';
import { useHistory } from 'react-router';
import { useNavigationStore } from 'store/navigation';
import styles from './index.module.scss';

export interface MenuItemProps extends TvFocusableProps {
  title?: string;
  icon: string;
  isMenuOpened: boolean;
  isActive: boolean;
  isHidden?: boolean;
  categoryId: number;
  focusable?: boolean;
}

const MenuItem = (
  {
    icon,
    isMenuOpened,
    title,
    parentNodeId,
    isActive,
    categoryId,
    isHidden,
    focusable = true,
    ...props
  }: MenuItemProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { push } = useHistory();
  const resetReturnState = useNavigationStore(
    (state) => state.resetReturnState
  );

  const onMenuItemClick = () => {
    resetReturnState();
    return isMenuOpened ? push(`/category/${categoryId}`) : null;
  };
  return (
    <TvFocusable
      parentNodeId={parentNodeId}
      ref={ref}
      className={cn(styles.menuItemWrapper, {
        [styles.menuItemOpened]: isMenuOpened,
        [styles.menuItemHidden]: isHidden,
      })}
      activeClass={styles.menuItemActive}
      onClick={onMenuItemClick}
      {...props}
    >
      <img src={icon} alt={title} className={styles.menuItemIcon} />

      {title && (
        <div
          className={cn(styles.menuItemTitle, {
            [styles.menuItemHidden]: !isMenuOpened,
          })}
        >
          {title}
        </div>
      )}
    </TvFocusable>
  );
};

export default forwardRef(MenuItem);
