import { usePlayerStore } from 'components/Player/store';
import { selectPlayerRef } from 'components/Player/store/selectors';
import { useEffect, useRef } from 'react';
import _throttle from 'lodash/throttle';
import _debounce from 'lodash/debounce';
import {
  MediaKeyButton,
  getRemoteControllerKey,
} from 'utils/remoteControllerHelpers';

const OVERLAY_HIDE_TIMEOUT = 3000;

const usePlayerOverlayEvents = (
  setOverlayVisibility: (val: boolean) => void
) => {
  const throttled = useRef(_throttle((callback) => callback(), 300));
  const debounced = useRef(
    _debounce((callback) => callback(), OVERLAY_HIDE_TIMEOUT)
  );

  const playerRef = usePlayerStore(selectPlayerRef);
  const videoElement = playerRef?.getMediaElement();

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const key = getRemoteControllerKey(e);
      if (
        !videoElement ||
        [MediaKeyButton.BACK].includes(key as MediaKeyButton)
      )
        return;
      setOverlayVisibility(true);
      debounced.current.cancel();
      debounced.current(() => setOverlayVisibility(false));
    };

    const magicNavigatorListener = () => {
      throttled.current(() => {
        debounced.current.cancel();
        setOverlayVisibility(true);

        debounced.current(() => {
          setOverlayVisibility(false);
        });
      });
    };

    window.addEventListener('mousemove', magicNavigatorListener);
    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
      window.removeEventListener('mousemove', magicNavigatorListener);
    };
  }, [setOverlayVisibility, videoElement]);

  useEffect(() => {
    const cancelThrottle = throttled.current.cancel;
    return () => {
      cancelThrottle();
    };
  }, []);
};

export default usePlayerOverlayEvents;
