import { useLayoutEffect } from 'react';
import cn from 'classnames';
import { navigation } from 'ConfigProvider';
import { useHistory, useParams } from 'react-router-dom';
import { Content } from 'types/content';
import { ReactComponent as AdBadge } from 'assets/icons/ad-badge.svg';
import useBackController from 'hooks/useBackController';
import { PLAYER_NODE_ID, PlayerNodeParentId } from 'types/player';
import { usePlayerStore } from '../Player/store';
import { selectPlaybackState } from '../Player/store/selectors';
import styles from './index.module.scss';
import Controls from './components/Controls';
import usePlayerOverlayEvents from './hooks/usePlayerOverlayEvents';
import useHandleEndOfVideo from './hooks/useHandleEndOfVideo';

interface VideoPlayerOverlayProps {
  children: React.ReactNode;
  content?: Content;
  isVisible: boolean;
  setOverlayVisible: (val: boolean) => void;
}

interface RouteIdParams {
  categoryId: string;
  contentId: string;
}

const VideoPlayerOverlay = ({
  content,
  children,
  isVisible,
  setOverlayVisible,
}: VideoPlayerOverlayProps) => {
  const { goBack } = useHistory();
  const { categoryId, contentId } = useParams<RouteIdParams>();
  const playbackState = usePlayerStore(selectPlaybackState);
  usePlayerOverlayEvents(setOverlayVisible);
  useHandleEndOfVideo({
    onNoAssetToPlay: goBack,
    onAssetSwitch: () => setOverlayVisible(true),
  });

  useBackController(goBack);

  useLayoutEffect(() => {
    [...Object.values(PlayerNodeParentId)]
      .reverse()
      .forEach((parentId, index) => {
        navigation.registerParentNode(parentId, {
          orientation: [PlayerNodeParentId.CONTROLS].includes(parentId)
            ? 'vertical'
            : 'horizontal',
          parent: PLAYER_NODE_ID,
          index,
          isIndexAlign: true,
        });
      });
  }, []);

  return (
    <>
      <section
        className={cn(styles.overlayContainer, {
          [styles.hidden]: !isVisible || playbackState === 'adPlaying',
        })}
      >
        <div className={styles.topBar}>
          <h2 className={styles.category}>{content?.seriesName}</h2>
          <h1 className={styles.title}>{content?.title}</h1>
        </div>

        <Controls
          isOverlayVisible={isVisible}
          categoryId={categoryId}
          contentId={contentId}
        />
      </section>

      {playbackState === 'adPlaying' && <AdBadge className={styles.adBadge} />}
      {children}
    </>
  );
};

export default VideoPlayerOverlay;
