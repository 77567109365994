import { ReactNode } from 'react';
import useTvConfig from 'hooks/useTvConfig';
import Navigation from 'navigation';

export const navigation = new Navigation();

const ConfigProvider = ({ children }: { children: ReactNode }) => {
  useTvConfig({
    fakeMagicRemote: process.env.NODE_ENV === 'development',
  });
  return <>{children}</>;
};
export default ConfigProvider;
