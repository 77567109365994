import { useEffect, useState } from 'react';

const useKeyCombo = (combo: string[]) => {
  const [keyCombo, setKeyCombo] = useState<string[]>([]);
  const [isMatching, setIsMatching] = useState(false);

  useEffect(() => {
    // TODO GET RID OF ON PROD
    const updateKeyCombo = ({ key }: KeyboardEvent) => {
      if (combo.includes(key) && combo.indexOf(key) === keyCombo.length)
        setKeyCombo([...keyCombo, key]);
    };

    window.addEventListener('keydown', updateKeyCombo);

    return () => {
      window.removeEventListener('keydown', updateKeyCombo);
    };
  }, [combo, isMatching, keyCombo, setKeyCombo]);

  useEffect(() => {
    const comboIsMatching =
      combo.length === keyCombo.length &&
      combo.every((key, index) => index === keyCombo.indexOf(key));

    if (comboIsMatching) {
      setIsMatching(!isMatching);
      setKeyCombo([]);
    }
  }, [combo, isMatching, keyCombo, keyCombo.length]);

  return isMatching;
};

export default useKeyCombo;
