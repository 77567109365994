import cn from 'classnames';
import { formattedTime } from 'utils/time';
import { ReactNode } from 'react';
import styles from './index.module.scss';

interface TimeControlProps {
  currentTime: number;
  duration: number;
  visible?: boolean;
  children: ReactNode;
}
const TimeControl = ({
  currentTime,
  duration,
  visible = true,
  children,
}: TimeControlProps) => {
  const passedTime = formattedTime(currentTime || 0);
  const fullTime = formattedTime(duration || 0);

  return (
    <div className={styles.root}>
      <div
        className={cn(styles.timeContainer, {
          [styles.visible]: !!duration && visible,
        })}
      >
        {passedTime}
      </div>
      <div className={styles.trackContainer}>{children}</div>
      <div className={styles.wholeTime}>{fullTime}</div>
    </div>
  );
};

export default TimeControl;
