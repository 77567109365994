import Carousel, { OnCarouselItemClickProps } from 'components/Carousel';
import { useAppStore } from 'store/app';
import cn from 'classnames';
import { Category } from 'types/category';
import { CarouselData } from 'types/carousel';
import { useCallback, useState } from 'react';
import useBackController from 'hooks/useBackController';
import CloseAppModal from 'components/CloseAppModal';
import useMoveToPlayer from 'hooks/useMoveToPlayer';
import { Directions } from 'lrud';
import { selectReturnState } from 'store/navigation/selectors';
import { useNavigationStore } from 'store/navigation';
import useThrottledWheel from '../../hooks/useThrottledWheel';
import { navigation } from '../../ConfigProvider';
import { selectIsMenuOpened } from '../../store/menu/selectors';
import { useMenuStore } from '../../store/menu';
import SkeletonLoader from '../../components/SkeletonLoader';
import styles from './index.module.scss';

const Home = () => {
  const [isCloseAppModalVisible, setIsCloseAppModalVisible] = useState(false);
  useBackController(() => setIsCloseAppModalVisible(true));
  const moveToPlayer = useMoveToPlayer();
  const isMenuOpened = useMenuStore(selectIsMenuOpened);
  const { returnCollectionIndex } = useNavigationStore((state) => ({
    returnCollectionIndex: selectReturnState(state).returnCollectionIndex,
    resetReturnState: state.resetReturnState,
  }));

  //Home page displays all categories
  const { appState, categories } = useAppStore(({ categories, appState }) => ({
    categories,
    appState,
  }));

  const carouselData: CarouselData[] = categories.map((category: Category) => ({
    contents: category.contents,
    title: category.name,
    icon: category.images.small_icon,
    categoryId: category.id,
  }));

  const onCarouselItemClick = useCallback(
    ({ categoryId, contentId }: OnCarouselItemClickProps) =>
      moveToPlayer({
        categoryId,
        contentId,
      }),
    [moveToPlayer]
  );

  useThrottledWheel(
    useCallback(
      ({ deltaY }) => {
        if (isMenuOpened) return;
        navigation.go('main', deltaY > 0 ? Directions.DOWN : Directions.UP);
      },
      [isMenuOpened]
    )
  );

  return (
    <>
      <div className={styles.homePageWrapper}>
        <div className={styles.homePageHeaderWrapper}>
          <h1 className={cn(styles.homePageText, styles.homePageTitle)}>
            Dude perfect
          </h1>
          <h1 className={cn(styles.homePageText, styles.homePageSubtitle)}>
            Play for more
          </h1>
        </div>

        <div className={styles.homeCarouselsWrapper}>
          {appState.categoriesLoading && (
            <>
              <SkeletonLoader focusable={false} assignFocus={false} isVisible />
              <SkeletonLoader focusable={false} assignFocus={false} isVisible />
            </>
          )}
          {!appState.categoriesLoading &&
            carouselData.map((carouselData: CarouselData, index: number) => (
              <Carousel
                key={`${carouselData.title}_${index}`}
                sliderInnerClassName={styles.sliderInner}
                carouselData={carouselData}
                index={index}
                carouselParentNodeId="main"
                options={{
                  shouldAssignFocus: returnCollectionIndex === index || !index,
                }}
                onItemClick={onCarouselItemClick}
              />
            ))}
        </div>
      </div>

      {isCloseAppModalVisible && (
        <CloseAppModal
          open={isCloseAppModalVisible}
          onClose={() => setIsCloseAppModalVisible(false)}
        />
      )}
    </>
  );
};

export default Home;
