import { useCallback, useEffect } from 'react';

import { useAppStore } from 'store/app';
import { selectIsTTSEnabled, selectPlatform } from 'store/app/selectors';

const IGNORE_COMPONENTS = ['carousel', 'empty'];

interface TTSProps {
  nodeId: string;
  isActive: boolean;
}

const useVizioTextToSpeech = ({ nodeId, isActive }: TTSProps) => {
  const { platform, ttsEnabled } = useAppStore((state) => ({
    platform: selectPlatform(state),
    ttsEnabled: selectIsTTSEnabled(state),
  }));

  const getText = useCallback(() => {
    const numberIndex = nodeId.search(/[0-9]/);
    const firstNumber = numberIndex > 0 ? numberIndex : 0;
    const text = firstNumber ? nodeId.slice(0, firstNumber) : nodeId;
    return text.replaceAll('_', ' ');
  }, [nodeId]);

  const shouldIgnore = IGNORE_COMPONENTS.some((component) =>
    nodeId.includes(component)
  );

  useEffect(() => {
    if (shouldIgnore || !isActive || !ttsEnabled) return;

    const text = getText();

    window.VIZIO?.Chromevox.play(text);

    return () => window.VIZIO?.Chromevox.cancel();
  }, [getText, isActive, nodeId, platform, shouldIgnore, ttsEnabled]);
};

export default useVizioTextToSpeech;
