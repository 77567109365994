import { create } from 'zustand';

export interface Menu {
  isMenuOpened: boolean;
  setIsMenuOpened: (opened: boolean) => void;
  activeMenuNodeId: string;
  setActiveMenuNodeId: (id: string) => void;
  lastFocusedMenuNodeId: string;
  setLastFocusedMenuNodeId: (id: string) => void;
}

export const useMenuStore = create<Menu>((set) => ({
  isMenuOpened: false,
  setIsMenuOpened: (opened) => set({ isMenuOpened: opened }),
  activeMenuNodeId: '',
  setActiveMenuNodeId: (id) => set({ activeMenuNodeId: id }),
  lastFocusedMenuNodeId: '',
  setLastFocusedMenuNodeId: (id) => set({ lastFocusedMenuNodeId: id }),
}));
