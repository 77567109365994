import { Dispatch, SetStateAction, useEffect } from 'react';
import { navigation } from 'ConfigProvider';
import { clampValue } from 'utils/clampValue';
import {
  MediaKeyButton,
  getRemoteControllerKey,
} from 'utils/remoteControllerHelpers';
import { LrudDirection } from 'utils/types/lrudDirection';
import { useHistory } from 'react-router-dom';
import { usePlayerStore } from 'components/Player/store';
import {
  selectPlayerActions,
  selectPlayerRef,
} from 'components/Player/store/selectors';
import { isCarousel } from 'utils/components';
import {
  PlayerNodeChildId,
  PlayerNodeParentId,
  VideoHtmlEvent,
} from 'types/player';
import { AutoSeekState } from './useAutoSeek';

const setSeekDirection = (speed: number) =>
  speed > 0 ? LrudDirection.RIGHT : speed === 0 ? null : LrudDirection.LEFT;

interface UseSeekEventsProps {
  setAutoSeek: Dispatch<SetStateAction<AutoSeekState>>;
  moveTrack: (direction: LrudDirection) => void;
  playPauseHandler: () => void;
  cancelCurrentTimeInterval: () => void;
  setCurrentTimeInterval: () => void;
  setSeekedTime: () => void;
  clearSeekTimeout: () => void;
}

const useSeekEvents = ({
  setAutoSeek,
  moveTrack,
  playPauseHandler,
  cancelCurrentTimeInterval,
  setCurrentTimeInterval,
  setSeekedTime,
  clearSeekTimeout,
}: UseSeekEventsProps) => {
  const { push } = useHistory();
  const playerActions = usePlayerStore(selectPlayerActions);
  const playerRef = usePlayerStore(selectPlayerRef);
  const videoElement = playerRef?.getMediaElement();
  const playbackState = usePlayerStore(({ state }) => state);

  useEffect(() => {
    if (!videoElement) return;

    const endListener = () => cancelCurrentTimeInterval();
    const pauseListener = () => {
      cancelCurrentTimeInterval();
      playerActions.pause();
    };
    const playListener = () => {
      setAutoSeek({
        direction: null,
        speed: 0,
      });
      if (playbackState === 'seeking') {
        setSeekedTime();
      }
      cancelCurrentTimeInterval();
      clearSeekTimeout();
      setCurrentTimeInterval();
      playerActions.play();
    };
    videoElement.addEventListener(VideoHtmlEvent.ENDED, endListener);
    videoElement.addEventListener(VideoHtmlEvent.PAUSE, pauseListener);
    videoElement.addEventListener(VideoHtmlEvent.PLAY, playListener);
    return () => {
      videoElement.removeEventListener(VideoHtmlEvent.ENDED, endListener);
      videoElement.removeEventListener(VideoHtmlEvent.PAUSE, pauseListener);
      videoElement.removeEventListener(VideoHtmlEvent.PLAY, playListener);
    };
  }, [
    cancelCurrentTimeInterval,
    clearSeekTimeout,
    playbackState,
    playerActions,
    setAutoSeek,
    setCurrentTimeInterval,
    setSeekedTime,
    videoElement,
  ]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const key = getRemoteControllerKey(e);
      const seekbarNode = `${PlayerNodeParentId.CONTROLS}_${PlayerNodeChildId.SEEKBAR_CONTROL}`;

      const openOverlayAndSeek = (direction: LrudDirection) => {
        const seekbarFocused = navigation.focusedNode?.id === seekbarNode;

        if (!seekbarFocused && !isCarousel(navigation.focusedNode?.id)) {
          navigation.assignFocus(seekbarNode);
          moveTrack(direction);
        }
      };
      switch (key) {
        case MediaKeyButton.STOP:
          push('/');
          return;
        case MediaKeyButton.PLAY:
        case MediaKeyButton.PAUSE:
          playPauseHandler();
          break;
        case LrudDirection.LEFT:
          openOverlayAndSeek(LrudDirection.LEFT);
          break;
        case LrudDirection.RIGHT:
          openOverlayAndSeek(LrudDirection.RIGHT);
          break;
        case MediaKeyButton.FORWARD:
        case MediaKeyButton.F: // note: for local testing
          setAutoSeek((prev) => ({
            direction: setSeekDirection(prev.speed + 1),
            speed: clampValue(prev.speed + 1, -3, 3),
          }));
          break;
        case MediaKeyButton.BACKWARD:
        case MediaKeyButton.B: // note: for local testing
          setAutoSeek((prev) => ({
            direction: setSeekDirection(prev.speed - 1),
            speed: clampValue(prev.speed - 1, -3, 3),
          }));
      }
    };

    window.addEventListener('keydown', listener);

    return () => window.removeEventListener('keydown', listener);
  }, [moveTrack, playPauseHandler, push, setAutoSeek]);
};

export default useSeekEvents;
