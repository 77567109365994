import { ReactComponent as SeekIcon } from 'assets/icons/seek.svg';
import cn from 'classnames';
import styles from './index.module.scss';

interface SeekSpeedIndicatorProps {
  speed: number;
}

const SeekSpeedIndicator = ({ speed }: SeekSpeedIndicatorProps) => {
  if (speed === 0) return null;

  return (
    <div className={styles.seekSpeedIndicator}>
      <SeekIcon
        className={cn(styles.seekIcon, {
          [styles.seekIconLeft]: speed < 0,
        })}
      />
      <span className={styles.seekSpeed}>{Math.abs(speed)}x</span>
    </div>
  );
};

export default SeekSpeedIndicator;
