import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
export interface Log {
  type: string;
  log: string;
  timestamp: string;
}

const DevConsole = () => {
  const [consoleLogs, setConsoleLogs] = useState<Log[]>([]);

  const onLog = useCallback(() => {
    const logs = window.logs || [];
    setConsoleLogs([...logs]);
  }, [setConsoleLogs]);

  useEffect(() => {
    onLog();

    window.addEventListener('log', onLog);
    return () => window.removeEventListener('log', onLog);
  }, [onLog]);

  return (
    <div className={styles.root}>
      <section className={styles.logContainer}>
        {consoleLogs?.map(({ type, log, timestamp }: any, index: any) => (
          <div key={index} className={cn(styles.logItem, styles[type])}>
            <span>{type}</span>
            <span className={styles.logContent}>{log}</span>
            <span className={styles.timestamp}>{timestamp}</span>
          </div>
        ))}
      </section>
    </div>
  );
};

export default DevConsole;
