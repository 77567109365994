import { useCallback, useLayoutEffect, useRef } from 'react';

const useInjectPreconnectTags = () => {
  const origin = useRef(window.origin || window.location.origin);

  const createLinkTagWithPrefix = useCallback(
    ({
      prefix,
      rel,
    }: {
      prefix: string;
      rel: 'preconnect' | 'dns-prefetch';
    }) => {
      const link = document.createElement('link');
      link.rel = rel;
      link.href = origin.current.replace('https://tv.', `https://${prefix}.`);
      return link;
    },
    []
  );

  useLayoutEffect(() => {
    if (process.env.NODE_ENV === 'development') return;

    const head = document.querySelector('head');
    const preconnectTags = [
      createLinkTagWithPrefix({ prefix: 'assets', rel: 'preconnect' }),
      createLinkTagWithPrefix({ prefix: 'assets', rel: 'dns-prefetch' }),
      createLinkTagWithPrefix({ prefix: 'images', rel: 'preconnect' }),
      createLinkTagWithPrefix({ prefix: 'images', rel: 'dns-prefetch' }),
    ];

    preconnectTags.forEach((tag) => head?.appendChild(tag));
  }, [createLinkTagWithPrefix]);
};

export default useInjectPreconnectTags;
