const getOuterSize = (
  el?: HTMLElement | null,
  axis: 'width' | 'height' = 'height'
): number => {
  if (!el) return 0;
  const { height, width } = el.getBoundingClientRect();
  const elSize = axis === 'height' ? height : width;
  const style = window.getComputedStyle(el);
  const margin =
    axis === 'height'
      ? parseInt(style.marginTop) + parseInt(style.marginBottom)
      : parseInt(style.marginLeft) + parseInt(style.marginRight);

  return elSize + margin;
};

export default getOuterSize;
