import cn from 'classnames';
import LinearWrapper from 'components/LinearWrapper';
import ProgressIndicator from 'components/ProgressIndicator';
import styles from './index.module.scss';

const AppLoadingIndicator = ({
  transparent = false,
}: {
  transparent?: boolean;
}) => (
  <LinearWrapper
    className={cn(styles.root, {
      [styles.transparent]: transparent,
    })}
    transparent={transparent}
    data-testid="AppLoadingIndicator"
  >
    <ProgressIndicator variant="circular" />
  </LinearWrapper>
);

export default AppLoadingIndicator;
