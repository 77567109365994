import { useCallback, useMemo } from 'react';

export interface FrameDimensions {
  posterWidth: number;
  posterHeight: number;
  carouselHeight: number;
  listingRowHeight: number;
}

export enum PageComponentType {
  CAROUSEL = 'CAROUSEL',
  CAROUSEL_HD = 'CAROUSEL_HD',
}

//Values for FullHD screen and above
const DEFAULT_CAROUSEL_POSTER_WIDTH = 350;
const DEFAULT_CAROUSEL_POSTER_HEIGHT = 197;
const DEFAULT_CAROUSEL_HEIGHT = 327; //poster height = 197px + title height 90px + gap height 40px (all in variables.scss)
const DEFAULT_LISTING_POSTER_HEIGHT = 227; //poster height = 197px + margin bottom 30px (all in variables.scss)

//Values for HD screen and below
const CAROUSEL_1280_POSTER_WIDTH = 280;
const CAROUSEL_1280_POSTER_HEIGHT = 157;
const CAROUSEL_1280_HEIGHT = 287; //poster height = 157px + title height 90px + gap height 40px (all in variables.scss)
const LISTING_1280_POSTER_HEIGHT = 187; //poster height = 157px + margin bottom 30px (all in variables.scss)

export const ratioStrategy: {
  [key in PageComponentType]: FrameDimensions;
} = {
  [PageComponentType.CAROUSEL]: {
    posterWidth: DEFAULT_CAROUSEL_POSTER_WIDTH,
    posterHeight: DEFAULT_CAROUSEL_POSTER_HEIGHT,
    carouselHeight: DEFAULT_CAROUSEL_HEIGHT,
    listingRowHeight: DEFAULT_LISTING_POSTER_HEIGHT,
  },
  [PageComponentType.CAROUSEL_HD]: {
    posterWidth: CAROUSEL_1280_POSTER_WIDTH,
    posterHeight: CAROUSEL_1280_POSTER_HEIGHT,
    carouselHeight: CAROUSEL_1280_HEIGHT,
    listingRowHeight: LISTING_1280_POSTER_HEIGHT,
  },
};

const useRatioStrategy = () => {
  const isHDScreen = useMemo(() => window.innerWidth <= 1280, []);

  const { posterHeight, posterWidth, carouselHeight, listingRowHeight } =
    useMemo(
      () => (isHDScreen ? ratioStrategy.CAROUSEL_HD : ratioStrategy.CAROUSEL),
      [isHDScreen]
    );

  const getCarouselTransformValue = useCallback(
    (activeCarouselIndex: number) => activeCarouselIndex * carouselHeight,
    [carouselHeight]
  );

  const getListingPosterTransformValue = useCallback(
    (activeCarouselIndex: number) => activeCarouselIndex * listingRowHeight,
    [listingRowHeight]
  );

  return {
    posterHeight,
    posterWidth,
    getCarouselTransformValue,
    getListingPosterTransformValue,
  };
};

export default useRatioStrategy;
