import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { selectDeviceId, selectPlatform } from 'store/app/selectors';
import { TvPlatform } from 'utils/types/tvPlatform';
import { useAppStore } from 'store/app';

const DEVICE_ID_METHODS = {
  [TvPlatform.WEBOS]() {
    return new Promise((res) => {
      window.webOS.service.request('luna://com.webos.service.sm', {
        method: 'deviceid/getIDs',
        parameters: {
          idType: ['LGUDID'],
        },
        onSuccess: (
          { idList }: any /* webos lib has got wrongly typed api resp */
        ) => res(idList[0].idValue),
        onFailure: () => {
          const deviceId = uuid();
          return res(deviceId);
        },
      });
    });
  },

  [TvPlatform.BROWSER]() {
    return this.getRandomUid();
  },

  getRandomUid() {
    const deviceId = uuid();
    return Promise.resolve<string>(deviceId);
  },
};

const useSetDeviceId = () => {
  const { platform, deviceId, setDeviceUid } = useAppStore((state) => ({
    platform: selectPlatform(state),
    deviceId: selectDeviceId(state),
    setDeviceUid: state.setDeviceUid,
  }));

  useEffect(() => {
    if (deviceId || !platform) return;
    const requiredPlatform = [TvPlatform.WEBOS].includes(platform as TvPlatform)
      ? (platform as TvPlatform)
      : TvPlatform.BROWSER;
    const getNewDeviceUid = DEVICE_ID_METHODS[requiredPlatform].bind(
      DEVICE_ID_METHODS
    ) as () => Promise<string>;
    getNewDeviceUid().then(setDeviceUid);
  }, [deviceId, platform, setDeviceUid]);
};

export default useSetDeviceId;
