import { create } from 'zustand';

type PlaybackState =
  | 'playing'
  | 'paused'
  | 'buffering'
  | 'seeking'
  | 'init'
  | 'adPlaying';

export interface PlayerStore {
  state: PlaybackState;
  playerRef?: shaka.Player;
  currentTime: number;
  hasAds: boolean;
  controlsHidden: boolean;
  actions: {
    play: () => void;
    pause: () => void;
    buffer: () => void;
    seek: () => void;
    setCurrentTime: (time: number) => void;
    setPlayerRef: (player?: shaka.Player) => void;
    reset: () => void;
    setAdPlaying: () => void;
    setControlsHidden: (hidden: boolean) => void;
  };
}

export const usePlayerStore = create<PlayerStore>()((set, get) => ({
  state: 'init',
  playerRef: undefined,
  currentTime: 0,
  hasAds: false,
  isAdPlaying: false,
  controlsHidden: false,
  actions: {
    play: () => set({ state: 'playing' }),
    pause: () => set({ state: 'paused' }),
    buffer: () => set({ state: 'buffering' }),
    seek: () => set({ state: 'seeking' }),
    setAdPlaying: () => set({ state: 'adPlaying' }),
    setPlayerRef: (player) => {
      const playerAlreadySet = !!get().playerRef;
      if (!playerAlreadySet) {
        set({ playerRef: player });
      }
    },
    setCurrentTime: (time) => set({ currentTime: time }),
    setControlsHidden: (hidden) => set({ controlsHidden: hidden }),
    reset: () =>
      set({
        state: 'init',
        playerRef: undefined,
        currentTime: 0,
        hasAds: false,
        controlsHidden: false,
      }),
  },
}));
