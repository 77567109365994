import { useQuery } from '@tanstack/react-query';
import { getCategories, getCategoryById } from 'actions/categories';
import { useEffect, useState } from 'react';
import { useAppStore } from 'store/app';
import { useUserStore } from 'store/user';
import { selectUserDetails } from 'store/user/selectors';
import { Category } from 'types/category';

const useFetchCategories = () => {
  const [categoriesIds, setCategoriesIds] = useState<number[]>([]);
  const userDetails = useUserStore(selectUserDetails);
  const { setCategories, appState, setCategoriesLoading } = useAppStore(
    ({ setCategories, appState, setCategoriesLoading }) => ({
      setCategories,
      appState,
      setCategoriesLoading,
    })
  );

  //Fetch available categories
  const { isSuccess: categoriesFetched } = useQuery(
    ['availableCategories'],
    getCategories,
    {
      enabled: !!userDetails?.id,
      onSuccess: (categories) => setCategoriesIds(categories.map((c) => c.id)),
    }
  );

  //Fetch available categories details with content
  const { isSuccess: categoriesLoadingFinished } = useQuery(
    ['categories'],
    () => Promise.allSettled(categoriesIds.map((id) => getCategoryById(id))),
    {
      enabled: !!categoriesIds.length,
      onSuccess: (results) => {
        const categories = results.reduce<Category[]>(
          (filteredCategories, result) => {
            if (result.status === 'fulfilled') {
              filteredCategories.push(result.value);
            }
            return filteredCategories;
          },
          []
        );

        setCategories(categories);
      },
    }
  );

  useEffect(() => {
    if (appState.categoriesLoading && categoriesLoadingFinished) {
      setCategoriesLoading(false);
    }
  }, [
    appState.categoriesLoading,
    categoriesLoadingFinished,
    setCategoriesLoading,
  ]);

  return {
    availableCategoriesFetchedIds: categoriesLoadingFinished,
    categoriesFetched,
  };
};

export default useFetchCategories;
