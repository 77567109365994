import React, { useEffect, useRef, useState } from 'react';
import TvFocusable from 'components/TvFocusable';
import cn from 'classnames';
import { getRemoteControllerKey } from 'utils/remoteControllerHelpers';
import _throttle from 'lodash/throttle';
import { LrudDirection } from 'utils/types/lrudDirection';
import { indicatorPercent } from 'utils/time';
import { navigation } from 'ConfigProvider';
import { PlayerNodeChildId, PlayerNodeParentId } from 'types/player';
import styles from './index.module.scss';

const MOUSE_OVER_THROTTLE_TIMEOUT = 50;

interface SeekBarProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onIndicatorClick: () => void;
  onMoveTrack: (direction: LrudDirection) => void;
  seekTime: number | undefined;
  seeking: boolean;
  duration: number;
  isPlayingAds?: boolean;
  seekSpeed: number;
}

const SeekBar = ({
  onClick,
  onIndicatorClick,
  onMoveTrack,
  seekTime,
  seeking,
  duration,
  isPlayingAds,
  seekSpeed,
}: SeekBarProps) => {
  const visible = !!duration && !isPlayingAds && seekTime !== undefined;
  const [hoveredIndicatorPlacement, setHoveredIndicatorPlacement] = useState(0);
  const [mouseOverTrack, setMouseOverTrack] = useState(false);
  const mouseOverThrottled = useRef(
    _throttle((event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const parent = event?.target as HTMLDivElement | null;
      const rect = parent?.getBoundingClientRect();
      const position = (event?.clientX || 0) - (rect?.left || 0);
      const percent = indicatorPercent({
        duration: parent?.clientWidth,
        currentTime: position,
      });

      if (!parent) return;
      setHoveredIndicatorPlacement(percent);
    }, MOUSE_OVER_THROTTLE_TIMEOUT)
  );

  const isMounted = useRef<boolean>(false);
  const indicatorPlacement = indicatorPercent({
    duration,
    currentTime: seekTime || 0,
  });

  const mouseEnterHandler = () => setMouseOverTrack(true);
  const mouseLeaveHandler = () => {
    setMouseOverTrack(false);
    setHoveredIndicatorPlacement(indicatorPlacement);
  };

  useEffect(() => {
    if (duration) isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, [duration, seekTime]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (
        !navigation.focusedNode?.id.includes(PlayerNodeChildId.SEEKBAR_CONTROL)
      )
        return;
      const key = getRemoteControllerKey(e);
      switch (key) {
        case LrudDirection.LEFT:
          onMoveTrack(LrudDirection.LEFT);
          break;
        case LrudDirection.RIGHT:
          onMoveTrack(LrudDirection.RIGHT);
          break;
        case 'enter':
        case 'play':
          onIndicatorClick();
      }
    };
    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [onIndicatorClick, onMoveTrack]);

  useEffect(() => {
    if (seekSpeed !== 0) {
      navigation.assignFocus(
        `${PlayerNodeParentId.CONTROLS}_${PlayerNodeChildId.SEEKBAR_CONTROL}`
      );
    }
  }, [seekSpeed]);

  return (
    <div
      className={cn(styles.track, {
        [styles.visible]: visible,
      })}
      onClick={onClick}
      data-testid="seek-bar-track"
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onMouseMove={(e) =>
        mouseOverTrack && !isPlayingAds ? mouseOverThrottled.current(e) : null
      }
    >
      <div className={styles.bar}>
        <div
          className={cn(styles.trackPassed, {
            [styles.transition]: isMounted.current && !seekSpeed && !seeking,
          })}
          style={{
            transform: `translate3d(${indicatorPlacement - 100}%, 0px, 0px)`,
          }}
        />
      </div>

      <TvFocusable
        parentNodeId={PlayerNodeParentId.CONTROLS}
        nodeId={PlayerNodeChildId.SEEKBAR_CONTROL}
        onClick={onIndicatorClick}
        style={{
          transform: `translate3d(${
            mouseOverTrack ? hoveredIndicatorPlacement : indicatorPlacement
          }%, 0px, 0px)`,
        }}
        className={cn(styles.indicatorContainer, {
          [styles.transition]:
            isMounted.current && !seekSpeed && !seeking && !mouseOverTrack,
          [styles.indicatorActive]: mouseOverTrack,
        })}
        focusable={visible}
        activeClass={styles.indicatorActive}
      >
        <div className={styles.indicatorInfoContainer}>
          <span
            data-testid="seek-bar-indicator"
            className={styles.trackIndicator}
          />
        </div>
      </TvFocusable>
    </div>
  );
};

export default SeekBar;
