import { useCallback, useEffect, useRef, useState } from 'react';
import {
  MediaKeyButton,
  getRemoteControllerKey,
} from 'utils/remoteControllerHelpers';
import { LrudDirection } from 'utils/types/lrudDirection';

const INTERVAL_MAP = new Map<number, number>([
  [1, 600],
  [2, 600 / 6],
  [3, 600 / 12],
]);

export interface AutoSeekState {
  direction: LrudDirection | null;
  speed: number;
}

const useAutoSeek = ({
  videoElement,
  seekFn,
}: {
  videoElement: HTMLMediaElement | null | undefined;
  seekFn: (direction: LrudDirection) => void;
}) => {
  const [autoSeek, setAutoSeek] = useState<AutoSeekState>({
    direction: null,
    speed: 0,
  });

  const seekIntervalId = useRef<null | ReturnType<typeof setInterval>>(null);

  const clearSeekInterval = useCallback(() => {
    if (seekIntervalId.current) {
      clearInterval(seekIntervalId.current);
      seekIntervalId.current = null;
    }
  }, []);
  const createSeekInterval = useCallback(() => {
    if (!videoElement || !Object.values(autoSeek).every((value) => !!value))
      return;
    clearSeekInterval();
    const seekInterval = INTERVAL_MAP.get(Math.abs(autoSeek.speed));
    seekIntervalId.current = setInterval(() => {
      seekFn(autoSeek.direction as LrudDirection);
    }, seekInterval);
  }, [videoElement, autoSeek, clearSeekInterval, seekFn]);

  useEffect(() => {
    if (autoSeek.speed === 0) {
      clearSeekInterval();
    } else {
      createSeekInterval();
    }
  }, [
    autoSeek.direction,
    autoSeek.speed,
    clearSeekInterval,
    createSeekInterval,
    seekFn,
  ]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const key = getRemoteControllerKey(e);
      const keysToIgnore = [
        MediaKeyButton.FORWARD,
        MediaKeyButton.BACKWARD,
        MediaKeyButton.F,
        MediaKeyButton.B,
      ];

      if (!keysToIgnore.includes(key as MediaKeyButton)) {
        setAutoSeek({
          direction: null,
          speed: 0,
        });
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [autoSeek.direction]);

  return {
    autoSeek,
    setAutoSeek,
    clearSeekInterval,
  };
};

export default useAutoSeek;
