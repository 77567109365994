import { createBrowserHistory } from 'history';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Router } from 'react-router-dom';
import ConsoleOverride from 'components/DevConsole/ConsoleOverride';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'App';
import ConfigProvider from './ConfigProvider';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'cross-fetch/polyfill';
import './index.scss';
import '@procot/webostv';

const history = createBrowserHistory();
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const run = async () => {
  // TODO get rid of on PROD
  if (process.env.NODE_ENV !== 'development') new ConsoleOverride();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        retry: false,
      },
    },
  });

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <ConfigProvider>
            <App />
          </ConfigProvider>
        </Router>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

run();
