import Menu from 'components/Menu';
import Home from 'pages/Home';
import Listing from 'pages/Listing';
import { Route, Switch } from 'react-router-dom';
import { categoryPagePath } from 'utils/types/navigation';
import styles from './index.module.scss';

const HomeRouter = () => {
  return (
    <>
      <Menu />

      <div className={styles.pageContentWrapper}>
        <Switch>
          <Route exact path={categoryPagePath} component={Listing} />
          <Route exact path="/" component={Home} />
        </Switch>
      </div>
    </>
  );
};

export default HomeRouter;
