import { RefObject, useCallback, useMemo, useState } from 'react';
import {
  selectActiveCarouselIndex,
  selectIsMagicRemoteActive,
} from 'store/navigation/selectors';
import { POSTER_PADDING_RIGHT } from 'components/Carousel';
import { navigation } from 'ConfigProvider';
import { useNavigationStore } from 'store/navigation';
import { Content } from 'types/content';
import useRatioStrategy from 'hooks/useRatioStrategy';
import { Category } from '../../../types/category';

interface CarouselNavigationProps {
  contents?: Content[];
  isCarouselActive: boolean;
  sliderInnerRef: RefObject<HTMLDivElement>;
  parentNodeId: string;
  categories?: Category[];
  arrows?: {
    left?: boolean;
    right?: boolean;
    bottom?: boolean;
    top?: boolean;
  };
}

const INIT_ARROW_STATE = {
  bottom: false,
  left: false,
  top: false,
  right: false,
};
const useCarouselNavigation = ({
  contents,
  categories,
  isCarouselActive,
  sliderInnerRef,
  parentNodeId,
  arrows,
}: CarouselNavigationProps) => {
  const [carouselStep, setCarouselStep] = useState(0);
  const { posterWidth } = useRatioStrategy();
  const totalPosterWidth = posterWidth + POSTER_PADDING_RIGHT;

  const { magicRemoteActive, activeCarouselIndex } = useNavigationStore(
    (state) => ({
      magicRemoteActive: selectIsMagicRemoteActive(state),
      activeCarouselIndex: selectActiveCarouselIndex(state),
    })
  );

  const getElementsInView = useCallback(() => {
    const sliderWidth = sliderInnerRef.current?.clientWidth || 0;
    const elementsInView = sliderWidth / totalPosterWidth;
    return elementsInView < 1 && elementsInView > 0
      ? 1
      : Math.floor(elementsInView);
  }, [sliderInnerRef, totalPosterWidth]);

  const magicNavigate = useCallback(
    (direction: 'left' | 'right') => {
      const elementsInView = Math.floor(getElementsInView());
      const fixedLeftIndex =
        carouselStep - elementsInView < 0 ? 0 : carouselStep - elementsInView;
      const nextIndex =
        direction === 'right' ? carouselStep + elementsInView : fixedLeftIndex;
      const node = `${parentNodeId}_${nextIndex}`;
      navigation.assignFocus(node);
      setCarouselStep(nextIndex);
    },
    [carouselStep, getElementsInView, parentNodeId]
  );

  const magicArrowsState = useMemo(() => {
    if (!contents || !magicRemoteActive || !isCarouselActive)
      return INIT_ARROW_STATE;

    const showBottomMagicNavigationArrow =
      !!arrows?.bottom && activeCarouselIndex + 1 < (categories?.length || 0);

    const showRightMagicNavigationArrow =
      !!contents[carouselStep + Math.floor(getElementsInView())] &&
      !!arrows?.right;

    return {
      bottom: showBottomMagicNavigationArrow,
      left: !!carouselStep && !!arrows?.left,
      top: !!activeCarouselIndex && !!arrows?.top,
      right: showRightMagicNavigationArrow,
    };
  }, [
    categories,
    contents,
    magicRemoteActive,
    activeCarouselIndex,
    isCarouselActive,
    arrows,
    carouselStep,
    getElementsInView,
  ]);

  return {
    carouselStep,
    setCarouselStep,
    onLeftArrowClick: () => magicNavigate('left'),
    onRightArrowClick: () => magicNavigate('right'),
    magicArrowsState,
  };
};

export default useCarouselNavigation;
