import axios, { AxiosError, AxiosInstance } from 'axios';
import { useUserStore } from 'store/user';
import axiosRetry from 'axios-retry';

let _api: AxiosInstance;

export const api = (url?: string) => {
  if (_api && !url) {
    return _api;
  }

  const instance = axios.create({
    baseURL: url,
    transitional: {
      clarifyTimeoutError: true,
    },
    timeout: 30000,
  });

  _api = instance;

  instance.interceptors.request.use((config) => {
    const userSessionKey = useUserStore.getState().details?.session_key;

    if (userSessionKey) {
      config.headers['X-Kidoodle-Session'] = userSessionKey;
    }

    return config;
  });
  return instance;
};

axiosRetry(api(), {
  retries: 5,
  retryDelay: () => 3000,
  retryCondition: (error) =>
    [
      AxiosError.ECONNABORTED,
      AxiosError.ERR_NETWORK,
      AxiosError.ETIMEDOUT,
      AxiosError.ERR_CANCELED,
    ].includes(error.code || ''),
});

export default api;
