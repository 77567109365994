import { useEffect, useRef, useState } from 'react';
import _throttle from 'lodash/throttle';
import _debounce from 'lodash/debounce';

const DEFAULT_THROTTLE = 500;
const SCROLLING_DEBOUNCE = 1000;

const useThrottledWheel = (
  callback: (event: WheelEvent) => void,
  shouldMount = true
) => {
  const debouncedScrolling = useRef(
    _debounce(() => setScrolling(false), SCROLLING_DEBOUNCE)
  );
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    const throttledCallback = _throttle((e: WheelEvent) => {
      callback(e);
      setScrolling(true);
      debouncedScrolling.current.cancel();
      debouncedScrolling.current();
    }, DEFAULT_THROTTLE);
    if (shouldMount)
      window.addEventListener('wheel', throttledCallback, { passive: false });

    return () => window.removeEventListener('wheel', throttledCallback);
  }, [callback, shouldMount]);

  return { scrolling };
};

export default useThrottledWheel;
