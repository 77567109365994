import { usePlayerStore } from 'components/Player/store';
import { selectPlayerRef } from 'components/Player/store/selectors';
import useMoveToPlayer from 'hooks/useMoveToPlayer';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppStore } from 'store/app';
import { selectCategories } from 'store/app/selectors';
import { useNavigationStore } from 'store/navigation';
import { VideoHtmlEvent } from 'types/player';

interface RouteIdParams {
  categoryId: string;
  contentId: string;
}

interface HandleEndOfVideoProps {
  onNoAssetToPlay: () => void;
  onAssetSwitch: () => void;
}

const useHandleEndOfVideo = ({
  onAssetSwitch,
  onNoAssetToPlay,
}: HandleEndOfVideoProps) => {
  const { playerRef, playerActions } = usePlayerStore((state) => ({
    playerRef: selectPlayerRef(state),
    playerActions: state.actions,
  }));
  const setReturnState = useNavigationStore((state) => state.setReturnState);
  const { categoryId, contentId } = useParams<RouteIdParams>();
  const categories = useAppStore(selectCategories);
  const currentCategory = useMemo(
    () => categories.find((category) => category.id.toString() === categoryId),
    [categories, categoryId]
  );
  const moveToPlayer = useMoveToPlayer();

  const getNextAssetToPlay = useCallback(() => {
    if (!currentCategory) return;

    const currentAssetIndex = currentCategory.contents.findIndex(
      (asset) => asset.id.toString() === contentId
    );
    if (currentAssetIndex === -1) return;

    const nextAsset = currentCategory.contents[currentAssetIndex + 1];
    if (!nextAsset) return currentCategory.contents[0];

    return nextAsset;
  }, [currentCategory, contentId]);

  useEffect(() => {
    const videoRef = playerRef?.getMediaElement();
    if (!videoRef) return;
    const listener = () => {
      const nextAsset = getNextAssetToPlay();
      const returnPosterIndex = currentCategory?.contents.findIndex(
        ({ id }) => id === nextAsset?.id
      );
      setReturnState({ returnPosterIndex });
      if (!nextAsset) return onNoAssetToPlay();

      playerActions.reset();
      onAssetSwitch();

      moveToPlayer({
        categoryId: parseInt(categoryId, 10),
        contentId: nextAsset.id,
        navigationVariant: 'replace',
      });
    };

    videoRef.addEventListener(VideoHtmlEvent.ENDED, listener);

    return () => {
      videoRef.removeEventListener(VideoHtmlEvent.ENDED, listener);
    };
  }, [
    categoryId,
    currentCategory?.contents,
    getNextAssetToPlay,
    moveToPlayer,
    onAssetSwitch,
    onNoAssetToPlay,
    playerActions,
    playerRef,
    setReturnState,
  ]);
};

export default useHandleEndOfVideo;
