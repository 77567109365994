import api from 'api';
import { CurrentUserBody, SignInBody, User } from 'types/user';

export const userSignIn = async (payload: SignInBody) => {
  const { data } = await api().post<User>('/users/login', payload);

  return data;
};

export const getCurrentUser = async (url: string, payload: CurrentUserBody) => {
  const { data } = await api(url).post<User>('/currentUser', payload);

  return data;
};
