import { useCallback } from 'react';
import { useUserStore } from 'store/user';
import { selectProfiles } from 'store/user/selectors';

const useExitApp = () => {
  const { profiles, setCurrentProfile } = useUserStore((state) => ({
    profiles: selectProfiles(state),
    setCurrentProfile: state.setCurrentProfile,
  }));

  return useCallback(() => {
    if (!!profiles?.length) {
      setCurrentProfile(null);
    }

    return window.close();
  }, [profiles, setCurrentProfile]);
};

export default useExitApp;
