import { useCallback, useEffect, useRef } from 'react';
import { useAppStore } from 'store/app';
import { selectPlatform } from 'store/app/selectors';
import { TvPlatform } from 'utils/types/tvPlatform';

export default function useNetworkConnectionService() {
  const networkErrorTimer = useRef<NodeJS.Timeout>();

  const { setIsNetworkConnected, platform } = useAppStore((state) => ({
    setIsNetworkConnected: state.setIsNetworkConnected,
    platform: selectPlatform(state),
  }));

  const clearNetworkTimeout = useCallback(() => {
    if (networkErrorTimer.current) clearTimeout(networkErrorTimer.current);
  }, []);

  const networkWebosService = useCallback(() => {
    const webOS = window.webOS;

    if (!webOS) return;

    window.webOS.service.request('luna://com.palm.connectionmanager', {
      method: 'getStatus',
      subscribe: true,
      onSuccess: ({ isInternetConnectionAvailable }: any) => {
        if (isInternetConnectionAvailable) {
          clearNetworkTimeout();
        }

        setIsNetworkConnected(isInternetConnectionAvailable);
      },
    });
  }, [clearNetworkTimeout, setIsNetworkConnected]);

  const defaultInternetConnectionHandler = useCallback(() => {
    setIsNetworkConnected(navigator.onLine);
  }, [setIsNetworkConnected]);

  const defaultNetworkService = useCallback(() => {
    setIsNetworkConnected(navigator.onLine);
    window.addEventListener('online', defaultInternetConnectionHandler);
    window.addEventListener('offline', defaultInternetConnectionHandler);
  }, [defaultInternetConnectionHandler, setIsNetworkConnected]);

  useEffect(() => {
    if (!platform) return;

    const networkMethods = {
      [TvPlatform.WEBOS]: networkWebosService,
      [TvPlatform.BROWSER]: defaultNetworkService,
    };

    networkMethods[platform as TvPlatform]?.();
  }, [defaultNetworkService, networkWebosService, platform]);

  useEffect(() => {
    return () => {
      window.removeEventListener('online', defaultInternetConnectionHandler);
      window.removeEventListener('offline', defaultInternetConnectionHandler);
    };
  }, [defaultInternetConnectionHandler]);
}
