import { ForwardedRef, forwardRef, useEffect } from 'react';
import cn from 'classnames';
import { ratioStrategy } from '../../hooks/useRatioStrategy';
import { navigation } from '../../ConfigProvider';
import TvFocusable from '../TvFocusable';
import styles from './index.module.scss';

const NODE_ID = 'skeleton_loader';
const SKELETON_ELEMENTS = 10;
interface SkeletonLoaderProps {
  parentNodeId?: string;
  focusable?: boolean;
  assignFocus?: boolean;
  isVisible?: boolean;
}

const SkeletonLoader = (
  {
    parentNodeId = 'root',
    focusable = true,
    assignFocus,
    isVisible,
  }: SkeletonLoaderProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { posterWidth, posterHeight } = ratioStrategy['CAROUSEL'];
  const slides = Array.from(
    { length: SKELETON_ELEMENTS },
    (_, index) => index + 1
  );

  useEffect(() => {
    return () => {
      const nodeId = navigation.focusedNode?.id || '';
      if (nodeId.includes('skeleton')) {
        setTimeout(() => navigation.assignFocus(`${parentNodeId}_0`));
      }
      navigation.unregisterNode(`${parentNodeId}_${NODE_ID}`, false);
    };
  }, [parentNodeId]);

  return (
    <section className={styles.root} ref={ref}>
      <article className={styles.header}></article>
      <TvFocusable
        key={NODE_ID}
        parentNodeId={parentNodeId}
        nodeId={NODE_ID}
        unmountFocusOnDestroy={false}
        focusable={focusable}
        assignFocus={assignFocus}
        forceRefocus
      >
        <article
          className={cn(styles.tile, { [styles.hidden]: !isVisible })}
          style={{ minHeight: `${posterHeight}px` }}
        >
          {slides.map((_, index) => (
            <div
              key={index}
              className={styles.slide}
              style={{
                minWidth: `${posterWidth}px`,
                height: `${posterHeight}px`,
              }}
            />
          ))}
        </article>
      </TvFocusable>
    </section>
  );
};

export default forwardRef(SkeletonLoader);
