import { usePlayerStore } from 'components/Player/store';
import {
  selectCurrentTime,
  selectPlaybackState,
  selectPlayerActions,
  selectPlayerRef,
} from 'components/Player/store/selectors';
import { useCallback, useRef } from 'react';
import _clamp from 'lodash/clamp';
import { LrudDirection } from 'utils/types/lrudDirection';

interface UseMoveSeekTimeProps {
  cancelTimeInterval: () => void;
  setTimeInterval: () => void;
}

const REWIND_STEP = 15;
const PLAY_AFTER_SEEK_TIMEOUT = 2000;

const seekTime = (
  direction: LrudDirection | null = LrudDirection.RIGHT,
  duration = 0,
  prev?: number
): number => {
  if (!prev) return 0;
  const step = REWIND_STEP * (direction === LrudDirection.RIGHT ? 1 : -1);
  return _clamp(prev + step, 0, duration);
};

const useMoveSeekTime = ({
  cancelTimeInterval,
  setTimeInterval,
}: UseMoveSeekTimeProps) => {
  const seekTimeoutId = useRef<null | ReturnType<typeof setTimeout>>(null);
  const playerActions = usePlayerStore(selectPlayerActions);
  const playbackState = usePlayerStore(selectPlaybackState);
  const playerRef = usePlayerStore(selectPlayerRef);
  const currentTime = usePlayerStore(selectCurrentTime);
  const videoElement = playerRef?.getMediaElement();
  const seekDirection = useRef<LrudDirection | null>(null);
  const clearSeekTimeout = useCallback(() => {
    if (seekTimeoutId.current) {
      clearTimeout(seekTimeoutId.current);
    }
  }, []);
  const currentTimeRef = useRef<number | undefined>(currentTime);
  const setSeekedTime = useCallback(() => {
    if (!videoElement) return;
    playerActions.play();
    videoElement.currentTime = currentTimeRef.current || 0;
    videoElement.play();
    setTimeInterval();
  }, [playerActions, setTimeInterval, videoElement]);

  const deferSeekFinish = useCallback(
    (direction: LrudDirection) => {
      seekDirection.current = direction;
      playerActions.pause();
      videoElement?.pause();
      clearSeekTimeout();
      seekTimeoutId.current = setTimeout(
        setSeekedTime,
        PLAY_AFTER_SEEK_TIMEOUT
      );
    },
    [clearSeekTimeout, playerActions, setSeekedTime, videoElement]
  );

  const moveTrack = useCallback(
    (direction: LrudDirection) => {
      const shouldNotTrackMove =
        !videoElement ||
        [LrudDirection.UP, LrudDirection.DOWN].includes(direction) ||
        playbackState === 'adPlaying';
      if (shouldNotTrackMove) return;
      const seekTimeValue = seekTime(
        direction,
        videoElement.duration,
        currentTime
      );
      cancelTimeInterval();
      deferSeekFinish(direction);
      playerActions.seek();
      playerActions.setCurrentTime(seekTimeValue);
      currentTimeRef.current = seekTimeValue;

      if (!videoElement.paused) {
        videoElement.pause();
        playerActions.pause();
      }
    },
    [
      cancelTimeInterval,
      currentTime,
      deferSeekFinish,
      playbackState,
      playerActions,
      videoElement,
    ]
  );

  const onTrackIndicatorClick = useCallback(() => {
    if (
      !videoElement ||
      ['buffering', 'playing'].includes(playbackState) ||
      currentTime === undefined
    )
      return;
    if (playbackState === 'buffering') {
      videoElement.pause();
      videoElement.currentTime = currentTime;
      clearSeekTimeout();
    }
    videoElement.play();
  }, [clearSeekTimeout, currentTime, playbackState, videoElement]);

  return { moveTrack, onTrackIndicatorClick, clearSeekTimeout, setSeekedTime };
};

export default useMoveSeekTime;
