import { usePlayerStore } from 'components/Player/store';
import {
  selectPlayerActions,
  selectPlayerRef,
} from 'components/Player/store/selectors';
import { useCallback, useRef } from 'react';

const TIME_UPDATE_INTERVAL = 250; // note: 2.5ms not 1s prevents lags with player time update (ad breaks, etc.)

const useSeekIntervals = () => {
  const playerActions = usePlayerStore(selectPlayerActions);
  const playerRef = usePlayerStore(selectPlayerRef);
  const timeUpdateIntervalId = useRef<null | ReturnType<typeof setInterval>>(
    null
  );

  const cancelCurrentTimeInterval = useCallback(() => {
    if (timeUpdateIntervalId.current)
      clearInterval(timeUpdateIntervalId.current);
  }, []);

  const setCurrentTimeInterval = useCallback(() => {
    cancelCurrentTimeInterval();
    const setCurrentTimeHandler = () => {
      const videoElement = playerRef?.getMediaElement();
      if (!videoElement) return;
      const time = Math.round(videoElement.currentTime);
      playerActions.setCurrentTime(time);
    };
    setCurrentTimeHandler();
    timeUpdateIntervalId.current = setInterval(
      setCurrentTimeHandler,
      TIME_UPDATE_INTERVAL
    );
  }, [cancelCurrentTimeInterval, playerActions, playerRef]);

  return { cancelCurrentTimeInterval, setCurrentTimeInterval };
};

export default useSeekIntervals;
