import { Profile, UserPlan } from '@laminar-product/client-commons-core/core';
import { UserState } from '.';

export const selectUserEmail = (state: UserState): string =>
  state.details?.email || '';

export const selectUserPlan = (state: UserState) =>
  state.details?.plan || ({} as UserPlan);

export const selectProfiles = (state: UserState): Profile[] | null =>
  state.profiles;

export const selectCurrentProfile = (state: UserState): Profile | null =>
  state.currentProfile;

export const selectVerifiedByPin = (state: UserState): boolean =>
  state.verifiedByPin;

export const selectUserDetails = (state: UserState) => state.details;

export const selectTermsAccepted = (state: UserState) => state.termsAccepted;
