import { navigation } from 'ConfigProvider';
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useNavigationStore } from 'store/navigation';
import {
  selectActiveCarouselIndex,
  selectIsMagicRemoteActive,
  selectReturnState,
} from 'store/navigation/selectors';
import cn from 'classnames';
import Poster from 'components/Poster';
import { Content } from 'types/content';
import { CarouselData } from 'types/carousel';
import { selectIsMenuOpened } from 'store/menu/selectors';
import { useMenuStore } from 'store/menu';
import { ReactComponent as ArrowNavigator } from 'assets/icons/arrow-navigation.svg';
import { Directions } from 'lrud';
import useRatioStrategy from 'hooks/useRatioStrategy';
import { useAppStore } from 'store/app';
import { selectCategories } from 'store/app/selectors';
import styles from './index.module.scss';
import CarouselTitle from './components/CarouselTitle';
import useCarouselNavigation from './hooks/useCarouselNavigation';
import { getCarouselParentId } from './utils/getCarouselParentId';

export interface OnCarouselItemClickProps {
  categoryId: number;
  contentId: number;
}
interface CarouselProps {
  carouselData: CarouselData;
  index: number;
  carouselParentNodeId: string;
  onActive?: () => void;
  onInActive?: () => void;
  onItemClick: ({ categoryId, contentId }: OnCarouselItemClickProps) => void;
  sliderInnerClassName?: string;
  options?: {
    disableYTransform?: boolean;
    externalCarouselNodeId?: string;
    shouldAssignFocus?: boolean;
    disableHoverFocus?: boolean;
    arrows?: {
      left?: boolean;
      right?: boolean;
      top?: boolean;
      bottom?: boolean;
    };
    displayTitle?: boolean;
  };
}

export const POSTER_PADDING_RIGHT = 30;

const Carousel = ({
  carouselData,
  index,
  carouselParentNodeId,
  onActive,
  onInActive,
  onItemClick,
  sliderInnerClassName,
  options: {
    disableYTransform = false,
    externalCarouselNodeId = 'home',
    shouldAssignFocus = false,
    disableHoverFocus = true,
    arrows = {
      left: true,
      right: true,
      top: true,
      bottom: true,
    },
    displayTitle = true,
  } = {},
}: CarouselProps) => {
  const categories = useAppStore(selectCategories);
  const isMenuOpened = useMenuStore(selectIsMenuOpened);
  const [isCarouselActive, setIsCarouselActive] = useState(false);
  const parentNodeId = getCarouselParentId(externalCarouselNodeId, index);
  const { posterWidth, getCarouselTransformValue } = useRatioStrategy();

  const {
    activeCarouselIndex,
    setActiveCarouselIndex,
    magicRemoteActive,
    returnState: { returnPosterIndex },
    setReturnState,
  } = useNavigationStore((state) => ({
    activeCarouselIndex: selectActiveCarouselIndex(state),
    setActiveCarouselIndex: state.setActiveCarouselIndex,
    magicRemoteActive: selectIsMagicRemoteActive(state),
    returnState: selectReturnState(state),
    setReturnState: state.setReturnState,
  }));

  const carouselTransformValue = getCarouselTransformValue(activeCarouselIndex);
  const contents = carouselData.contents;
  const sliderInnerRef = useRef<HTMLDivElement | null>(null);
  const posterRef = useRef<HTMLDivElement | null>(null);
  const sliderItemsRef = useRef<HTMLDivElement | null>(null);
  const totalPosterWidth = posterWidth + POSTER_PADDING_RIGHT;
  const {
    carouselStep,
    setCarouselStep,
    magicArrowsState,
    onRightArrowClick,
    onLeftArrowClick,
  } = useCarouselNavigation({
    isCarouselActive,
    contents,
    categories,
    sliderInnerRef,
    parentNodeId,
    arrows,
  });
  const goUp = () => navigation.go('main', Directions.UP);
  const goDown = () => navigation.go('main', Directions.DOWN);
  const posterFocusHandler = useCallback(
    (stepIndex: number) => {
      setActiveCarouselIndex(index);
      setCarouselStep(stepIndex);
      setIsCarouselActive(true);
    },
    [setActiveCarouselIndex, index, setCarouselStep]
  );

  const onClickHandler = (content: Content) => {
    setActiveCarouselIndex(0);
    setCarouselStep(0);
    setIsCarouselActive(false);

    const currentCategoryIndex = categories.findIndex(
      (category) => category.id === carouselData.categoryId
    );
    const currentContentIndex = categories[
      currentCategoryIndex
    ]?.contents.findIndex((item) => item.id === content.id);
    setReturnState({
      returnCollectionIndex: currentCategoryIndex,
      returnPosterIndex: currentContentIndex,
    });
    onItemClick({
      categoryId: carouselData.categoryId,
      contentId: content.id,
    });
  };

  useLayoutEffect(() => {
    navigation.registerParentNode(parentNodeId, {
      orientation: 'horizontal',
      parent: carouselParentNodeId,
      index: index + 1,
    });
  }, [index, parentNodeId, carouselParentNodeId]);

  useEffect(() => {
    navigation.onEvent(parentNodeId, 'onInactive', () => {
      setIsCarouselActive(false);
      onInActive?.();
    });
    navigation.onEvent(parentNodeId, 'onActive', () => {
      setIsCarouselActive(true);
      onActive?.();
    });
  }, [onActive, onInActive, parentNodeId]);

  return (
    <section
      ref={sliderInnerRef}
      className={cn(styles.carouselRoot, {
        [styles.carouselRootActive]: isCarouselActive && !isMenuOpened,
        [styles.pointerEventsNone]: !isCarouselActive,
      })}
      style={{
        transform: !disableYTransform
          ? `translateY(-${carouselTransformValue}px)`
          : undefined,
      }}
    >
      {displayTitle ? <CarouselTitle carouselData={carouselData} /> : null}
      {magicArrowsState?.top && (
        <ArrowNavigator
          onClick={goUp}
          className={cn(styles.arrow, styles.arrowTop)}
        />
      )}
      <div className={sliderInnerClassName}>
        <div
          className={cn(styles.arrowContainer, styles.leftArrowContainer, {
            [styles.hiddenArrow]: !magicArrowsState?.left,
          })}
        >
          <ArrowNavigator
            onClick={onLeftArrowClick}
            className={cn(styles.arrow, styles.arrowLeft, {
              [styles.hiddenArrow]: !magicArrowsState?.left,
            })}
          />
        </div>

        <div
          ref={sliderItemsRef}
          className={styles.sliderItems}
          style={{
            transform: `translateX(-${carouselStep * totalPosterWidth}px)`,
          }}
        >
          {contents.map((item: Content, i: number) => {
            const isActive =
              (i === carouselStep && isCarouselActive) ||
              (magicRemoteActive && isCarouselActive);
            const assignFocus =
              shouldAssignFocus && (returnPosterIndex === i || !i);
            return (
              <Poster
                key={`${item.id}_${i}`}
                ref={posterRef}
                parentNodeId={parentNodeId}
                nodeId={String(i)}
                data={item}
                onFocus={() => posterFocusHandler(i)}
                onClick={() => onClickHandler(item)}
                index={i}
                assignFocus={assignFocus}
                isActive={isActive}
                options={{
                  disableFrame: magicRemoteActive,
                  disableHoverFocus,
                }}
              />
            );
          })}
        </div>
        <div
          className={cn(styles.arrowContainer, styles.rightArrowContainer, {
            [styles.hiddenArrow]: !magicArrowsState?.right,
          })}
        >
          <ArrowNavigator
            onClick={onRightArrowClick}
            className={cn(styles.arrow, styles.arrowRight)}
          />
        </div>
      </div>
      {magicArrowsState?.bottom && (
        <ArrowNavigator
          onClick={goDown}
          className={cn(styles.arrow, styles.arrowBottom)}
        />
      )}
    </section>
  );
};
export default memo(Carousel);
