import { Directions, KeyEvent, Lrud, Node, NodeConfig, NodesBag } from 'lrud';
import _throttle from 'lodash/throttle';
import { NAVIGATION_PARENT_NODES } from 'types/navigation';
import { MenuNavigationNode } from '../components/Menu/utils/menuNavigationNodes';
import { PLAYER_NODE_ID } from '../types/player';

const NAVIGATION_THROTTLE = 50;
const navigation = new Lrud();

navigation.registerNode('root', {
  orientation: undefined,
  isFocusable: false,
});
navigation.registerNode('dashboard', {
  orientation: 'horizontal',
  isFocusable: false,
  parent: 'root',
});
navigation.registerNode('main', {
  orientation: 'vertical',
  isFocusable: false,
  parent: 'dashboard',
});
navigation.registerNode(MenuNavigationNode.MENU, {
  orientation: 'horizontal',
  isFocusable: false,
  parent: 'dashboard',
  index: 0,
});
navigation.registerNode(MenuNavigationNode.MAIN_MENU, {
  orientation: 'vertical',
  isFocusable: false,
  parent: MenuNavigationNode.MENU,
});
navigation.registerNode(PLAYER_NODE_ID, {
  orientation: 'vertical',
  isFocusable: false,
  parent: 'root',
});
navigation.registerNode(NAVIGATION_PARENT_NODES.LISTING_NODE_ID, {
  orientation: 'vertical',
  isFocusable: false,
  parent: 'dashboard',
  isIndexAlign: true,
});

class Navigation {
  constructor() {
    this.init();
  }

  public init() {
    this.registerListener();
    navigation.on('move', (e) => (this.focusedNode = e.enter));
  }

  public blocked = false;
  public focusedNode: Node | undefined;
  public previousNode: Node | undefined;
  private keyDownEvent: KeyboardEvent | undefined;

  public unregisterNode = (nodeId: string, forceRefocus = true) =>
    navigation.unregister(nodeId, { forceRefocus });

  public registerListener() {
    window.addEventListener(
      'keydown',
      _throttle((event) => {
        event.preventDefault(); // This prevents default navigation on Vewd Platform
        if (
          // note: Keys: 'Unidentified', ' ' and 'KeyC' are for VIZIO Platform
          ['Unidentified', 'XF86AudioPause', ' '].includes(event.key) ||
          ['Pause', 'KeyC'].includes(event.code) ||
          this.blocked
        )
          return;

        this.keyDownEvent = event;

        navigation.handleKeyEvent(event);
      }, NAVIGATION_THROTTLE)
    );
  }

  public go = (id: string, direction: Directions) => {
    const currentNode = this.findNode(id);
    const nextNode = navigation.getNextFocusableChildInDirection(
      currentNode,
      direction
    );

    if (!nextNode) return;
    navigation.assignFocus(nextNode);
  };

  public getNextFocusableChildInDirection = (
    id: string,
    direction: Directions
  ) => {
    const currentNode = this.findNode(id);
    return navigation.getNextFocusableChildInDirection(currentNode, direction);
  };

  public nodes: NodesBag = navigation.nodes;

  public setNavigationBlocked = (block: boolean) => (this.blocked = block);

  public setPreviousNode = (previousNode: Node) =>
    (this.previousNode = previousNode);

  public registerParentNodeOutOfBox = (
    nodeId: string,
    orientation: 'horizontal' | 'vertical',
    isIndexAlign?: boolean
  ) => {
    if (this.nodes[nodeId]) return;
    this.registerParentNode(nodeId, {
      orientation,
      parent: 'root',
      isIndexAlign,
    });
  };

  public assignFocus = (id: string, checkFocusable = true) => {
    const node = this.nodes[id];
    if (!node?.isFocusable && checkFocusable) return;
    navigation.assignFocus(id);
    this.focusedNode = node;
  };

  public registerNode = ({ id, ...rest }: NodeConfig) => {
    if (!id || navigation.nodes[id]) return;
    navigation.registerNode(id, rest);
  };

  public onEvent = (
    nodeId: string,
    event:
      | 'onFocus'
      | 'onEnter'
      | 'onBlur'
      | 'onInactive'
      | 'onActive'
      | 'onLeave',
    callback: () => void
  ) => {
    const node = this.findNode(nodeId);
    if (!node) return;
    node[event] = callback;
  };

  public unregisterChildrenNodes = (parentId: string) =>
    navigation.unregister(parentId);

  public setFocusableNode = (nodeId: string, isFocusable: boolean) =>
    navigation.setNodeFocusable(nodeId, isFocusable);

  public findNode = (nodeId: string | number) => this.nodes[nodeId];

  public registerParentNode = (id: string, options: NodeConfig) => {
    if (this.nodes[id]) return;
    navigation.registerNode(id, options);
  };

  public getFocusedChild = () =>
    navigation.currentFocusNode || navigation.getCurrentFocusNode();
  public getKeyDownEvent = () => this.keyDownEvent;

  // Note: this is helpful in testing purposes. This event can be used to behave in tests as remote controller.
  public handleKeyEvent = (event: KeyEvent) => {
    this.keyDownEvent = new KeyboardEvent('keydown', event);
    return navigation.handleKeyEvent(event);
  };

  public recalculateFocus = () =>
    navigation.recalculateFocus(this.findNode('root'));
}

export default Navigation;
