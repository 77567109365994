import cn from 'classnames';
import styles from './index.module.scss';

const CircularProgress = ({ className }: { className?: string }) => (
  <div className={cn(styles.circularProgress, className)}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default CircularProgress;
