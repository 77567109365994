import { useCallback } from 'react';
import { useHistory } from 'react-router';

//Replace navigation variant is used when redirecting to another content from carousel inside the player
interface UseMoveToPlayerProps {
  categoryId?: number;
  contentId?: number;
  navigationVariant?: 'push' | 'replace';
}

const useMoveToPlayer = () => {
  const { push, replace } = useHistory();

  return useCallback(
    ({ categoryId, contentId, navigationVariant }: UseMoveToPlayerProps) => {
      if (!categoryId || !contentId) {
        return;
      }

      const path = `/category/${categoryId}/watch/${contentId}`;

      navigationVariant === 'replace' ? replace(path) : push(path);
    },
    [push, replace]
  );
};

export default useMoveToPlayer;
