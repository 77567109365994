import { memo } from 'react';
import { CarouselData } from 'types/carousel';
import styles from './index.module.scss';

interface CarouselTitleProps {
  carouselData: CarouselData;
}

const CarouselTitle = ({
  carouselData: { icon, title },
}: CarouselTitleProps) => {
  return (
    <div className={styles.header}>
      <img src={icon} alt={title} className={styles.icon} />
      <span className={styles.title}>{title}</span>
    </div>
  );
};

export default memo(CarouselTitle);
