import axios, { AxiosInstance } from 'axios';

let _api: AxiosInstance;

export const configApi = () => {
  if (_api) {
    return _api;
  }

  const instance = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_URL || '', // TODO change URL
    transitional: {
      clarifyTimeoutError: true,
    },
    timeout: 30000,
  });

  _api = instance;
  return instance;
};

export default configApi;
