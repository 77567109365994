import { TvPlatform } from 'utils/types/tvPlatform';
import { Category } from 'types/category';
import { Content } from 'types/content';
import { AppState } from '.';

export const selectPlatform = (
  state: AppState
): TvPlatform | string | undefined => state.appSettings?.platform;

export const selectDeviceId = (state: AppState): string =>
  state.appSettings?.deviceId || '';

export const selectApiBaseUrl = (state: AppState): string => state.apiBaseUrl;

export const selectIsTTSEnabled = (state: AppState): boolean =>
  state.appSettings.ttsEnabled;

export const selectCategories = (state: AppState): Category[] =>
  state.categories;

export const selectContent = (
  state: AppState,
  categoryId?: number,
  contentId?: number
): Content | undefined => {
  const category = state.categories.find((c) => c.id === categoryId);

  return category?.contents?.find((c) => c.id === contentId);
};

export const selectCategoryById = (
  state: AppState,
  categoryId?: number
): Category | undefined => state.categories.find((c) => c.id === categoryId);

export const selectIsNetworkConnected = (state: AppState) =>
  state.appSettings.isNetworkConnected;
