import { navigation } from 'ConfigProvider';
import {
  CSSProperties,
  ForwardedRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { selectIsMagicRemoteActive } from 'store/navigation/selectors';
import { NodeConfig, NodeId } from 'lrud';
import { LrudDirection } from 'utils/types/lrudDirection';
import { getRemoteControllerKey } from 'utils/remoteControllerHelpers';
import { useNavigationStore } from 'store/navigation';
import styles from './index.module.scss';
import useVizioTextToSpeech from './hooks/useVizioTextToSpeech';

export interface TvFocusableParentProps {
  [key: string]:
    | {
        parent?: TvFocusableParentProps;
        orientation?: 'horizontal' | 'vertical';
        index?: number;
      }
    | undefined;
}

export interface TvFocusableProps {
  children?: ReactElement | ReactNode;
  parentNodeId: string;
  nodeId: NodeId;
  index?: number;
  style?: CSSProperties;
  onFocus?: (id: NodeId) => void;
  onEnter?: (id: NodeId) => void;
  onLeave?: (id: NodeId, direction: LrudDirection) => void;
  onBlur?: (id: NodeId) => void;
  onClick?: (id: NodeId) => void;
  className?: string;
  unmountFocusOnDestroy?: boolean;
  focusable?: boolean;
  assignFocus?: boolean;
  activeClass?: string;
  activeLocked?: boolean;
  shouldRegister?: boolean;
  indexRange?: [number, number];
  isIndexAlign?: boolean;
  forceRefocus?: boolean;
  disableHoverFocus?: boolean;
  parentProps?: TvFocusableParentProps;
}

const TvFocusable = (
  {
    children,
    parentNodeId,
    nodeId,
    onClick,
    index,
    style,
    className,
    onFocus,
    onBlur,
    onEnter,
    onLeave,
    unmountFocusOnDestroy = true,
    focusable = true,
    assignFocus,
    activeClass,
    activeLocked,
    shouldRegister = true,
    indexRange,
    isIndexAlign,
    forceRefocus = false,
    disableHoverFocus = false,
    parentProps,
  }: TvFocusableProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const rootNodeId = `${parentNodeId}_${nodeId}`;
  const [isActive, setIsActive] = useState(false);
  const magicRemoteActive = useNavigationStore(selectIsMagicRemoteActive);
  const disallowMagicPointer = disableHoverFocus || !magicRemoteActive;

  useVizioTextToSpeech({ nodeId: rootNodeId, isActive });
  const shouldAddActiveClass = () =>
    !activeClass ? false : activeLocked || isActive;
  const onFocusHandler = useCallback(() => {
    setIsActive(true);
    onFocus?.(rootNodeId);
  }, [onFocus, rootNodeId]);
  const onBlurHandler = useCallback(() => {
    navigation.setPreviousNode(navigation.nodes[rootNodeId]);
    onBlur?.(rootNodeId);
    setIsActive(false);
  }, [onBlur, rootNodeId]);
  const onSelectHandler = useCallback(
    () => onClick?.(rootNodeId),
    [onClick, rootNodeId]
  );
  const onLeaveHandler = useCallback(() => {
    const direction = getRemoteControllerKey(
      navigation.getKeyDownEvent() as KeyboardEvent
    ) as LrudDirection;
    onLeave?.(rootNodeId, direction);
  }, [onLeave, rootNodeId]);

  const onEnterHandler = useCallback(() => {
    if (!magicRemoteActive) {
      setIsActive(true);
    }
    return onEnter?.(rootNodeId);
  }, [magicRemoteActive, onEnter, rootNodeId]);

  const registerEvents = useCallback(() => {
    const node = navigation.nodes[rootNodeId];

    if (!node) return;
    node.onSelect = onSelectHandler;
    node.onFocus = onFocusHandler;
    node.onBlur = onBlurHandler;
    node.onEnter = onEnterHandler;
    onLeave && (node.onLeave = onLeaveHandler);
  }, [
    onBlurHandler,
    onEnterHandler,
    onFocusHandler,
    onLeave,
    onLeaveHandler,
    onSelectHandler,
    rootNodeId,
  ]);

  const recurrentRegisterParentNode = useCallback(
    (parent: TvFocusableParentProps) => {
      Object.entries(parent).forEach(([parentId, props]) => {
        if (!props) return;
        const transformedProps: NodeConfig = {
          ...props,
          parent: Object.keys(props.parent || {})[0],
        };
        navigation.registerParentNode(parentId, transformedProps);
        if (props?.parent) {
          recurrentRegisterParentNode(props.parent);
        }
      });
    },
    []
  );

  const registerNode = useCallback(() => {
    if (parentProps && !navigation.nodes[Object.keys(parentProps)[0]]) {
      recurrentRegisterParentNode(parentProps);
    }
    navigation.registerNode({
      id: rootNodeId,
      parent: parentNodeId,
      index,
      isFocusable: focusable,
      indexRange,
      isIndexAlign,
    });
    registerEvents();
  }, [
    focusable,
    index,
    indexRange,
    isIndexAlign,
    parentNodeId,
    parentProps,
    recurrentRegisterParentNode,
    registerEvents,
    rootNodeId,
  ]);
  const mouseEnterListener = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (disallowMagicPointer) return;
      navigation.assignFocus(rootNodeId);
    },
    [disallowMagicPointer, rootNodeId]
  );
  const mouseLeaveListener = useCallback(() => {
    if (disallowMagicPointer) return;
    setIsActive(false);
  }, [disallowMagicPointer]);

  const onClickHandler = () => {
    if (!onClick || !magicRemoteActive) return;
    navigation.assignFocus(rootNodeId);
    onClick(rootNodeId);
  };

  useEffect(() => {
    if (!shouldRegister || navigation.nodes[rootNodeId]) return;
    registerNode();
  }, [rootNodeId, registerNode, shouldRegister]);

  useEffect(() => {
    setIsActive(false);
  }, [magicRemoteActive]);

  useEffect(() => {
    navigation.setFocusableNode(rootNodeId, focusable);
  }, [focusable, rootNodeId]);

  useEffect(() => {
    if (!assignFocus || !navigation.nodes[rootNodeId]) return;
    navigation.assignFocus(rootNodeId);
  }, [assignFocus, rootNodeId]);

  useEffect(() => {
    registerEvents();
  }, [registerEvents]);

  useEffect(() => {
    return () => {
      if (unmountFocusOnDestroy) {
        if (navigation.nodes[rootNodeId]?.onBlur) {
          navigation.nodes[rootNodeId].onBlur = undefined;
        }
        navigation.unregisterNode(rootNodeId, forceRefocus);
      }
    };
  }, [forceRefocus, rootNodeId, unmountFocusOnDestroy]);

  return (
    <div
      key={rootNodeId}
      id={rootNodeId}
      data-testid={rootNodeId}
      ref={ref}
      onMouseEnter={mouseEnterListener}
      onMouseLeave={mouseLeaveListener}
      onClick={onClickHandler}
      className={cn(className || styles.focus, [
        { [styles.noChildren]: !children },
        { [activeClass || '']: shouldAddActiveClass() },
        { [styles.mouseDisabled]: !magicRemoteActive },
      ])}
      style={style}
    >
      {children}
    </div>
  );
};

const TvFocusableForwardRef = forwardRef(TvFocusable);

export default memo(TvFocusableForwardRef);
