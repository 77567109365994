import { memo } from 'react';
import styles from 'components/CloseAppModal/index.module.scss';
import Modal from 'components/Modal';
import Button from 'components/Button';
import useBackController from 'hooks/useBackController';
import useExitApp from 'hooks/useExitApp';
import { NAVIGATION_PARENT_NODES } from 'types/navigation';
import { ReactComponent as PlayButton } from 'assets/icons/play-button.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';

interface CloseAppModalProps {
  open?: boolean;
  onClose: () => void;
}

const CloseAppModal = ({ open, onClose }: CloseAppModalProps) => {
  const parentNodeId = NAVIGATION_PARENT_NODES.CLOSE_APP_MODAL_NODE_ID;
  const exit = useExitApp();

  useBackController(() => {
    if (!open) return;

    onClose();
  });

  return (
    <Modal parentNodeId={parentNodeId} isOpen={!!open} orientation="horizontal">
      <Logo className={styles.logo} />

      <div className={styles.root}>
        <div className={styles.header}>Exit the app?</div>
        <div className={styles.buttonContainer}>
          <Button
            variant="secondary"
            tvFocusableProps={{
              parentNodeId,
              nodeId: 'no',
              onClick: onClose,
              assignFocus: true,
              forceRefocus: true,
            }}
            className={styles.button}
            icon={<PlayButton className={styles.icon} />}
          >
            No, take me back
          </Button>

          <Button
            variant="secondary"
            tvFocusableProps={{
              parentNodeId,
              nodeId: 'yes',
              onClick: exit,
              forceRefocus: true,
            }}
            className={styles.button}
          >
            Yes, exit the app
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(CloseAppModal);
