import flatMapDeep from 'lodash/flatMapDeep';
import { AVODParsedInfo, AVODResponse } from '../../../types/player';

export const parseAVODResponse = ({ avails }: AVODResponse): AVODParsedInfo[] =>
  avails?.map(
    ({
      ads,
      durationInSeconds: wholeDurationInSeconds,
      startTimeInSeconds: initStartTimeInSeconds,
    }) => ({
      adStart: initStartTimeInSeconds,
      adEnd: initStartTimeInSeconds + wholeDurationInSeconds,
      events: flatMapDeep(
        ads.map(({ trackingEvents }) =>
          trackingEvents.map(
            ({ beaconUrls, startTimeInSeconds, eventType, eventId }) => ({
              beaconUrls,
              startTimeInSeconds,
              eventType,
              eventId,
            })
          )
        )
      ),
    })
  ) || [];
