import { LrudDirection } from 'utils/types/lrudDirection';

export enum MediaKeyButton {
  BACK = 'back',
  BACKWARD = 'backward',
  BLUE = 'ColorF3Blue',
  NEXT_TRACK = 'next_track',
  PREVIOUS_TRACK = 'previous_track',
  DEFAULT = '',
  ENTER = 'enter',
  FORWARD = 'forward',
  GREEN = 'ColorF1Green',
  PAUSE = 'pause',
  PLAY = 'play',
  RED = 'ColorF0Red',
  SELECT = 'select',
  STOP = 'stop',
  YELLOW = 'ColorF2Yellow',
  B = 'B',
  F = 'F',
  CC = 'CaptureCaption',
}

export type MediaKey = LrudDirection | MediaKeyButton;

interface KeyInfo {
  code?: string;
  key?: string;
  keyCodes: Array<number | string | undefined>;
}

type Key = {
  [key in MediaKey]: KeyInfo;
};

const vewdKeyCode: { [key in MediaKey]?: number } = {
  [MediaKeyButton.BACK]:
    window?.vk_keys?.VK_BACK_SPACE || window?.vk_keys?.VK_BACK || 8,
  [MediaKeyButton.PLAY]: window?.vk_keys?.VK_PLAY || 415,
  [MediaKeyButton.PAUSE]: window?.vk_keys?.VK_PAUSE || 19,
  [MediaKeyButton.FORWARD]: window?.vk_keys?.VK_FAST_FWD || 417,
  [MediaKeyButton.BACKWARD]: window?.vk_keys?.VK_REWIND || 412,
  [MediaKeyButton.STOP]: window?.vk_keys?.VK_STOP || 413,
};

const keyboardToMediaKey: Key = {
  [LrudDirection.LEFT]: {
    code: 'ArrowLeft',
    keyCodes: [37],
  },
  [LrudDirection.UP]: {
    code: 'ArrowUp',
    keyCodes: [38],
  },
  [LrudDirection.RIGHT]: {
    code: 'ArrowRight',
    keyCodes: [39],
  },
  [LrudDirection.DOWN]: {
    code: 'ArrowDown',
    keyCodes: [40],
  },
  [MediaKeyButton.BACK]: {
    code: 'Escape',
    keyCodes: [461, 10009, vewdKeyCode[MediaKeyButton.BACK]],
  },
  [MediaKeyButton.ENTER]: {
    code: 'Enter',
    keyCodes: [13],
  },
  [MediaKeyButton.SELECT]: {
    code: 'Select',
    keyCodes: [65376],
  },
  [MediaKeyButton.PLAY]: {
    key: 's',
    keyCodes: [415, 10252, vewdKeyCode[MediaKeyButton.PLAY]],
  },
  [MediaKeyButton.PAUSE]: {
    code: 'Pause',
    key: 'p',
    keyCodes: [19, vewdKeyCode[MediaKeyButton.PAUSE]],
  },
  [MediaKeyButton.STOP]: {
    keyCodes: [413, vewdKeyCode[MediaKeyButton.STOP]],
  },
  [MediaKeyButton.FORWARD]: {
    code: 'ArrowRight',
    keyCodes: [417, vewdKeyCode[MediaKeyButton.FORWARD]],
  },
  [MediaKeyButton.BACKWARD]: {
    code: 'ArrowLeft',
    keyCodes: [412, vewdKeyCode[MediaKeyButton.BACKWARD]],
  },
  [MediaKeyButton.NEXT_TRACK]: {
    keyCodes: [418],
  },
  [MediaKeyButton.PREVIOUS_TRACK]: {
    keyCodes: [419],
  },
  [MediaKeyButton.RED]: {
    keyCodes: [403],
  },
  [MediaKeyButton.GREEN]: {
    keyCodes: [404],
  },
  [MediaKeyButton.YELLOW]: {
    keyCodes: [405],
  },
  [MediaKeyButton.BLUE]: {
    keyCodes: [406],
  },
  [MediaKeyButton.DEFAULT]: {
    keyCodes: [],
  },
  [MediaKeyButton.B]: {
    keyCodes: [66],
    code: 'KeyB',
  },
  [MediaKeyButton.F]: {
    keyCodes: [70],
    code: 'KeyF',
  },
  // note: Vizio TV Captions button
  [MediaKeyButton.CC]: {
    keyCodes: [204],
    code: 'Unidentified',
  },
};

export const getRemoteControllerKey = ({
  code,
  key,
  keyCode,
}: KeyboardEvent): MediaKey => {
  const keys = Object.keys(keyboardToMediaKey) as Array<MediaKeyButton>;

  return (
    keys.find((keyName) => {
      const keyInfo: KeyInfo = keyboardToMediaKey[keyName as keyof Key];
      return (
        (!!code && keyInfo.code === code) ||
        (!!key && keyInfo.key === key) ||
        keyInfo.keyCodes.includes(keyCode)
      );
    }) || MediaKeyButton.DEFAULT
  );
};
