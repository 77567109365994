import { PlayerStore } from '.';

export const selectPlaybackState = (state: PlayerStore) => state.state;

export const selectPlayerRef = (state: PlayerStore) => state.playerRef;

export const selectIsPlaying = (state: PlayerStore) =>
  state.state === 'playing';
export const selectIsBuffering = (state: PlayerStore) =>
  ['buffering', 'init'].includes(state.state);

export const selectCurrentTime = (state: PlayerStore) => state.currentTime;

export const selectPlayerActions = (state: PlayerStore) => state.actions;
export const selectControlsIsHidden = (state: PlayerStore) =>
  state.controlsHidden;
