import { useAppStore } from 'store/app';
import {
  selectApiBaseUrl,
  selectDeviceId,
  selectIsNetworkConnected,
  selectPlatform,
} from 'store/app/selectors';
import { useQuery } from '@tanstack/react-query';
import { getConfiguration } from 'actions/config';
import { getCurrentUser, userSignIn } from 'actions/user';
import useTvSystemVersion from 'hooks/useTvSystemVersion';
import { useUserStore } from 'store/user';
import { useCallback, useMemo, useState } from 'react';
import { BaseUserPayloadBody } from 'types/user';
import { Route, Switch, useHistory } from 'react-router-dom';
import Player from 'pages/Player';
import { selectTermsAccepted } from 'store/user/selectors';
import HomeRouter from 'pages/HomeRouter';
import backgroundImage from 'assets/background-image.jpg';
import Terms from 'pages/Terms';
import SplashScreen from 'pages/SplashScreen';
import useFetchCategories from 'hooks/useFetchCategories';
import { playerPath } from 'utils/types/navigation';
import ErrorPage from 'pages/ErrorPage';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import useKeyCombo from 'hooks/useKeyCombo';
import DevConsole from 'components/DevConsole';
import useBackController from 'hooks/useBackController';
import packageJson from '../package.json';
import styles from './App.module.scss';

const KEY_COMBO = ['2', '1', '3', '7'];

const appVersion = packageJson.version;
const App = () => {
  const { categoriesFetched } = useFetchCategories();
  const [refreshLogin, setRefreshLogin] = useState(false);
  const isMatching = useKeyCombo(KEY_COMBO);

  const {
    deviceId,
    platform,
    apiBaseUrl,
    setApiBaseUrl,
    resetAppStore,
    isNetworkConnected,
  } = useAppStore((state) => ({
    deviceId: selectDeviceId(state),
    platform: selectPlatform(state),
    apiBaseUrl: selectApiBaseUrl(state),
    setApiBaseUrl: state.setApiBaseUrl,
    resetAppStore: state.resetAppStore,
    isNetworkConnected: selectIsNetworkConnected(state),
  }));

  const { setUserDetails, termsAccepted } = useUserStore((state) => ({
    setUserDetails: state.setUserDetails,
    termsAccepted: selectTermsAccepted(state),
  }));

  const platformVersion = useTvSystemVersion();

  const { isError: errorFetchingConfig, refetch: refetchConfig } = useQuery(
    ['config'],
    () =>
      getConfiguration({
        deviceID: deviceId,
        platform: platform as string,
        kdAppVersion: appVersion,
      }),
    {
      enabled: !!deviceId && !!platform,
      onSuccess: ({ apiBaseUrl }) => setApiBaseUrl(apiBaseUrl),
    }
  );

  const onAppRefresh = useCallback(() => {
    resetAppStore();
    refetchConfig();
  }, [refetchConfig, resetAppStore]);

  const basePayload: BaseUserPayloadBody = useMemo(
    () => ({
      platform: platform as string,
      device: '',
      deviceId,
      kdAppVersion: appVersion,
      kdApiVersion: '1',
      osVersion: platformVersion,
      screenResH: window.screen.height.toString(),
      screenResW: window.screen.width.toString(),
      screenDensityDPI: window.devicePixelRatio.toString(),
    }),
    [platform, deviceId, platformVersion]
  );

  const { isError: errorLogin } = useQuery(
    ['login'],
    () =>
      userSignIn({
        email: process.env.VITE_API_LOGIN || '',
        password: process.env.VITE_API_PASSWORD || '',
        ...basePayload,
      }),
    {
      enabled: refreshLogin,
      onSuccess: setUserDetails,
      onSettled: () => {
        setRefreshLogin(false);
      },
    }
  );

  //Error for user is omitted as it's part of the login flow
  useQuery(
    ['user'],
    () =>
      getCurrentUser(apiBaseUrl, {
        ...basePayload,
        requestCountry: true,
      }),
    {
      enabled: !!deviceId && !!platform && !!apiBaseUrl,
      onError: () => {
        setRefreshLogin(true);
      },
    }
  );

  const shouldDisplayErrorPage = useMemo(
    () => errorFetchingConfig || errorLogin || isNetworkConnected === false,
    [errorFetchingConfig, errorLogin, isNetworkConnected]
  );

  if (shouldDisplayErrorPage) {
    return (
      <ErrorPage
        onReload={onAppRefresh}
        reason={!isNetworkConnected ? 'networkError' : 'configError'}
      />
    );
  }

  if (!categoriesFetched) {
    return <SplashScreen />;
  }

  if (!termsAccepted) {
    return <Terms />;
  }

  return (
    <div
      className={styles.appWrapper}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Switch>
          <Route exact path={playerPath} component={Player} />
          <Route path="/" component={HomeRouter} />
        </Switch>
      </ErrorBoundary>
      {isMatching && <DevConsole />}
    </div>
  );
};

export default App;

function FallbackComponent({ error, resetErrorBoundary }: FallbackProps) {
  const { goBack } = useHistory();
  useBackController(() => {
    resetErrorBoundary();
    goBack();
  });

  const parseErrorReason = useMemo(() => {
    if (typeof error === 'object') {
      return error.message;
    }

    return error;
  }, [error]);

  return (
    <ErrorPage
      onReload={resetErrorBoundary}
      reason={parseErrorReason}
      code={error?.code || ''}
    />
  );
}
