export enum ShakaEvent {
  BUFFERING = 'buffering',
  ERROR = 'error',
  LOADED = 'loaded',
}
export interface ShakaError {
  severity: number;
  category: number;
  code: number;
  data?: { [key: string]: any };
}

export interface ShakaPlayerEvent extends Event {
  buffering?: boolean;
}
