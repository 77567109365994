import { useCallback, useEffect, useRef } from 'react';

import { selectPlatform } from 'store/app/selectors';
import { WebosCursorStateChangeEvent } from 'utils/types/webos';
import _throttle from 'lodash/throttle';
import { TvPlatform } from 'utils/types/tvPlatform';
import { selectIsMagicRemoteActive } from 'store/navigation/selectors';
import { useNavigationStore } from 'store/navigation';
import { useAppStore } from 'store/app';

interface MagicPointerConfig {
  fakeMagicRemote?: boolean;
}

const MAGIC_REMOTE_ACTIVE_TIMEOUT = 5000;
const MAGIC_REMOTE_ACTIVE_THROTTLE_TIMEOUT = 500;

export default function useWebosMagicPointer({
  fakeMagicRemote = false,
}: MagicPointerConfig) {
  const magicRemoteTimer = useRef<NodeJS.Timeout>();
  const platform = useAppStore(selectPlatform);
  const { magicRemoteActive, setMagicRemoteActive } = useNavigationStore(
    (state) => ({
      magicRemoteActive: selectIsMagicRemoteActive(state),
      setMagicRemoteActive: state.setMagicRemoteActive,
    })
  );

  const cursorWebosService = useCallback(
    (event: WebosCursorStateChangeEvent) => {
      const visibility = event.detail.visibility;
      setMagicRemoteActive(visibility);
    },
    [setMagicRemoteActive]
  );
  const mouseMoveListener = useCallback(
    () => setMagicRemoteActive(true),
    [setMagicRemoteActive]
  );

  useEffect(() => {
    if (!fakeMagicRemote) return;
    const listener = _throttle(() => {
      if (magicRemoteTimer.current) {
        clearTimeout(magicRemoteTimer.current);
      }

      magicRemoteTimer.current = setTimeout(
        () => setMagicRemoteActive(false),
        MAGIC_REMOTE_ACTIVE_TIMEOUT
      );
      if (magicRemoteActive) return;
      setMagicRemoteActive(true);
    }, MAGIC_REMOTE_ACTIVE_THROTTLE_TIMEOUT);

    const onKeyDown = () => {
      clearTimeout(magicRemoteTimer.current);
      setMagicRemoteActive(false);
    };

    window.addEventListener('mousemove', listener);
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('mousemove', listener);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [fakeMagicRemote, magicRemoteActive, setMagicRemoteActive]);

  useEffect(() => {
    if (platform !== TvPlatform.WEBOS) return;

    const focusListener = () => {
      window.addEventListener('mousemove', mouseMoveListener, { once: true });
    };
    window.addEventListener('focus', focusListener);

    return () => {
      window.removeEventListener('mousemove', mouseMoveListener);
      window.removeEventListener('focus', focusListener);
    };
  }, [mouseMoveListener, platform]);

  useEffect(() => {
    if (platform !== TvPlatform.WEBOS) return;

    const focusListener = () => {
      window.addEventListener('mousemove', mouseMoveListener, { once: true });
    };
    window.addEventListener('focus', focusListener);

    return () => {
      window.removeEventListener('mousemove', mouseMoveListener);
      window.removeEventListener('focus', focusListener);
    };
  }, [mouseMoveListener, platform]);

  useEffect(() => {
    if (platform !== TvPlatform.WEBOS) return;
    //note: any to overwrite custom webos event
    document.addEventListener<any>(
      'cursorStateChange',
      cursorWebosService,
      false
    );

    return () =>
      //note: any to overwrite custom webos event
      document.removeEventListener<any>(
        'cursorStateChange',
        cursorWebosService,
        false
      );
  }, [platform, cursorWebosService]);
}
