import Carousel, { OnCarouselItemClickProps } from 'components/Carousel';
import { useAppStore } from 'store/app';
import { selectCategoryById } from 'store/app/selectors';
import { useCallback, useMemo, useState } from 'react';
import useMoveToPlayer from 'hooks/useMoveToPlayer';
import {
  PLAYER_NODE_ID,
  PlayerNodeChildId,
  PlayerNodeParentId,
} from 'types/player';
import { selectPlayerActions } from '../../../Player/store/selectors';
import { usePlayerStore } from '../../../Player/store';
import { navigation } from '../../../../ConfigProvider';
import styles from './index.module.scss';

interface CurrentPlayingCollectionProps {
  categoryId?: number;
  currentContentId?: number;
}

const CAROUSEL_INDEX = 6;

const CurrentPlayingCollection = ({
  categoryId,
  currentContentId,
}: CurrentPlayingCollectionProps) => {
  const moveToPlayer = useMoveToPlayer();
  const playbackActions = usePlayerStore(selectPlayerActions);
  const [isCarouselActive, setIsCarouselActive] = useState(false);
  const category = useAppStore((state) =>
    selectCategoryById(state, categoryId)
  );
  const onActiveHandler = () => {
    setIsCarouselActive(true);
    playbackActions.setControlsHidden(true);
  };

  const onInActiveHandler = useCallback(() => {
    setIsCarouselActive(false);
    playbackActions.setControlsHidden(false);
  }, [playbackActions]);
  const onCarouselItemClick = ({
    categoryId,
    contentId,
  }: OnCarouselItemClickProps) => {
    onInActiveHandler();
    playbackActions.reset();
    navigation.assignFocus(
      `${PlayerNodeParentId.CONTROLS}_${PlayerNodeChildId.PLAY_CONTROL}`
    );
    moveToPlayer({ categoryId, contentId, navigationVariant: 'replace' });
  };
  const onMouseEnterHandler = () => {
    onActiveHandler();
    navigation.assignFocus(
      `carousel_${PLAYER_NODE_ID}_${CAROUSEL_INDEX}`,
      false
    );
  };
  const onMouseLeaveHandler = () => {
    onInActiveHandler();
  };

  const data = useMemo(() => {
    if (!category) return null;
    const { contents, name, images, id } = category;
    return {
      contents: contents.filter(({ id }) => id !== currentContentId),
      title: name,
      icon: images.small_icon,
      categoryId: id,
    };
  }, [category, currentContentId]);

  if (!data) return null;

  return (
    <div
      className={styles.root}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <div
        className={styles.carouselWrapper}
        style={{ transform: `translateY(${isCarouselActive ? '-70%' : '0'})` }}
      >
        <Carousel
          carouselParentNodeId={PLAYER_NODE_ID}
          carouselData={data}
          index={CAROUSEL_INDEX}
          onActive={onActiveHandler}
          onInActive={onInActiveHandler}
          onItemClick={onCarouselItemClick}
          options={{
            externalCarouselNodeId: 'player',
            disableYTransform: true,
            arrows: {
              bottom: false,
              top: false,
              right: true,
              left: true,
            },
            displayTitle: false,
          }}
        />
      </div>
    </div>
  );
};

export default CurrentPlayingCollection;
