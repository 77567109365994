export const indicatorPercent = ({
  duration,
  currentTime,
}: {
  duration?: number;
  currentTime: number;
}) => (duration ? (currentTime / duration) * 100 : 0);

export const formattedTime = (seconds: number): string => {
  const date = new Date(0);
  date.setSeconds(seconds);

  const formatted = date.toISOString().substring(11, 19);

  if (formatted.substring(0, 3) === '00:') {
    return formatted.replace('00:', '');
  }

  return formatted;
};
