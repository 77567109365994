import cn from 'classnames';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styles from './index.module.scss';

const LinearWrapper = ({
  children,
  className,
  transparent = false,
  ...props
}: {
  children: ReactNode;
  className?: string;
  transparent?: boolean;
}) =>
  createPortal(
    <div
      className={cn(styles.wrapper, className, {
        [styles.transparent]: transparent,
      })}
      {...props}
    >
      {children}
    </div>,
    document.getElementById('root') as HTMLElement
  );

export default LinearWrapper;
