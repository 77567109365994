import cn from 'classnames';
import { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import styles from './index.module.scss';

interface LazyImgProps {
  src: string;
  alt?: string;
  className?: string;
  onError?: (isError: boolean) => void;
}
const LazyImg = forwardRef(
  (
    { src, alt, className, onError }: LazyImgProps,
    ref: ForwardedRef<HTMLImageElement>
  ) => {
    const [loaded, setLoaded] = useState(false);

    const handleImageLoaded = useCallback(() => {
      if (loaded) return;
      setLoaded(true);
    }, [loaded, setLoaded]);
    const handleImageError = useCallback(() => onError?.(true), [onError]);

    return (
      <img
        ref={ref}
        src={src}
        data-src={src}
        onLoad={handleImageLoaded}
        onError={handleImageError}
        alt={alt}
        className={cn(className, styles.image, { [styles.loaded]: loaded })}
      />
    );
  }
);

export default LazyImg;
