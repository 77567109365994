import Button from 'components/Button';
import { NAVIGATION_PARENT_NODES } from 'types/navigation';
import { TvFocusableParentProps } from 'components/TvFocusable';
import useExitApp from 'hooks/useExitApp';
import { ReactComponent as Logo } from 'assets/logo.svg';
import React, { useMemo } from 'react';
import styles from './index.module.scss';

interface ErrorPageProps {
  onReload: () => void;
  reason?: 'networkError' | 'configError' | 'playbackError';
  code?: number;
}

interface ErrorPageContent {
  header: string;
  description: React.ReactNode;
  errorDetails?: string;
}

const ErrorPage = ({ onReload, reason, code }: ErrorPageProps) => {
  const exit = useExitApp();

  const { description, header, errorDetails }: ErrorPageContent =
    useMemo(() => {
      switch (reason) {
        case 'networkError':
          return {
            header: 'Connection error',
            description: (
              <span>
                Your device may not be connected to the Internet. <br />
                Please check your connection and try again.
              </span>
            ),
          };

        case 'playbackError':
          return {
            header: 'Playback error',
            description: (
              <span>
                There was a problem playing the video. <br /> Please try again
                later if the issue persists.
              </span>
            ),
            errorDetails: `Error code: ${code}`,
          };

        default:
          return {
            header: 'Something went wrong',
            description: (
              <span>
                There was a problem loading the app. <br /> Please try again
                later if the issue persists.
              </span>
            ),
          };
      }
    }, [code, reason]);

  const buttonParentProps: TvFocusableParentProps = {
    [NAVIGATION_PARENT_NODES.ERROR_PAGE_NODE_ID]: {
      orientation: 'horizontal',
    },
  };

  return (
    <div className={styles.pageWrapper}>
      <Logo className={styles.pageLogo} />

      <div className={styles.errorWrapper}>
        <div className={styles.errorHeader}>{header}</div>

        <div className={styles.errorDescription}>{description}</div>
        {errorDetails && <p className={styles.errorDetails}>{errorDetails}</p>}
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.button}
            variant="secondary"
            tvFocusableProps={{
              parentNodeId: NAVIGATION_PARENT_NODES.ERROR_PAGE_NODE_ID,
              nodeId: 'exit',
              onClick: exit,
              parentProps: buttonParentProps,
            }}
          >
            Exit the app
          </Button>

          <Button
            className={styles.button}
            variant="secondary"
            tvFocusableProps={{
              assignFocus: true,
              parentNodeId: NAVIGATION_PARENT_NODES.ERROR_PAGE_NODE_ID,
              nodeId: 'tryAgain',
              onClick: onReload,
              parentProps: buttonParentProps,
            }}
          >
            Try again
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
