import AppLoadingIndicator from 'components/AppLoadingIndicator';
import { useRef } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { ManifestResponse } from '../../actions/playback';
import useInitPlayer from './hooks/useInitPlayer';
import useShakaEvents from './hooks/useShakaEvents';
import styles from './index.module.scss';
import { usePlayerStore } from './store';
import { selectIsBuffering } from './store/selectors';

interface PlayerProps {
  manifestResponse?: ManifestResponse;
  onPlaying?: () => void;
}

const Player = ({ manifestResponse, onPlaying }: PlayerProps) => {
  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const containerElementRef = useRef<HTMLDivElement | null>(null);

  useShakaEvents({ onPlaying });
  const isBuffering = usePlayerStore(selectIsBuffering);

  const { error } = useInitPlayer({
    manifestResponse,
    videoEl: videoElementRef.current,
    containerEl: containerElementRef.current,
  });

  const { showBoundary } = useErrorBoundary();

  if (error) {
    showBoundary({
      message: 'playbackError',
      code: error.code,
    });
  }

  return (
    <div ref={containerElementRef} className={styles.root}>
      {isBuffering && <AppLoadingIndicator transparent />}
      <video ref={videoElementRef} className={styles.video} autoPlay />
    </div>
  );
};

export default Player;
