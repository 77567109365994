import axios from 'axios';
import { v4 as uuid } from 'uuid';
import qs from 'query-string';
import { Content } from '../types/content';

export interface ManifestResponse {
  manifestUrl: string;
  trackingUrl: string;
}

const getDeviceModel = async (): Promise<string> => {
  if (!webOS?.service) return Promise.resolve(uuid());
  return new Promise((res, rej) =>
    webOS?.service?.request('luna://com.webos.service.tv.systemproperty', {
      method: 'getSystemInfo',
      parameters: {
        keys: ['modelName'],
      },
      onComplete: function (inResponse: any) {
        const isSucceed = inResponse.returnValue;
        if (isSucceed) {
          res(inResponse.modelName);
          return;
        }
        res(uuid());
      },
    })
  );
};

const parseManifestUrl = async (content: Content) => {
  const modelID = (await getDeviceModel()) || uuid();
  const queryParams = qs.stringify({
    modelID,
    limitTracking: 0,
    trackingID: uuid(),
    deviceLanguage: navigator.language,
    categorySlug: content?.slug,
  });
  return `${content?.videoUrl}?${queryParams}`;
};
export const fetchSrcFromManifest = async (
  content: Content
): Promise<ManifestResponse> => {
  try {
    const { data } = await axios.get<ManifestResponse>(
      await parseManifestUrl(content)
    );
    return data;
  } catch (e) {
    return { manifestUrl: '', trackingUrl: '' };
  }
};
