import { useCallback, useState } from 'react';
import Player from 'components/Player';
import VideoPlayerOverlay from 'components/PlayerOverlay';
import usePlayerAsset from 'components/Player/hooks/usePlayerAsset';
import styles from './index.module.scss';

const PlayerPage = () => {
  const { manifestResponse, content } = usePlayerAsset();
  const [isOverlayVisible, setOverlayVisible] = useState(true);

  const onPlayingHandler = useCallback(() => setOverlayVisible(false), []);

  return (
    <div className={styles.root}>
      <VideoPlayerOverlay
        content={content}
        isVisible={isOverlayVisible}
        setOverlayVisible={setOverlayVisible}
      >
        <Player
          manifestResponse={manifestResponse}
          onPlaying={onPlayingHandler}
        />
      </VideoPlayerOverlay>
    </div>
  );
};

export default PlayerPage;
