import { Profile } from '@laminar-product/client-commons-core/core';
import { User } from 'types/user';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface UserState {
  details: User | null;
  profiles: Profile[] | null;
  currentProfile: Profile | null;
  sessionKey: string;
  verified?: boolean;
  verifiedByPin: boolean;
  setUserDetails: (userDetails: User) => void;
  setCurrentProfile: (profile: Profile | null) => void;
  termsAccepted?: boolean;
  setTermsAccepted: (payload: boolean) => void;
}

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      details: null,
      profiles: null,
      currentProfile: null,
      verified: false,
      sessionKey: '',
      verifiedByPin: false,
      termsAccepted: false,
      setUserDetails: (userDetails: User) =>
        set((state) => ({ ...state, details: userDetails })),
      setCurrentProfile: (profile: Profile | null) =>
        set((state) => ({
          ...state,
          ...(!state.currentProfile ||
          state.currentProfile.uuid !== profile?.uuid
            ? {}
            : {
                verifiedByPin: false,
              }),
          currentProfile: profile,
        })),
      setTermsAccepted: (termsAccepted: boolean) => {
        set(() => ({
          termsAccepted,
        }));
      },
    }),
    {
      name: 'app-user',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        currentProfile: state.currentProfile,
        termsAccepted: state.termsAccepted,
      }),
    }
  )
);
